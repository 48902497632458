import {Box, Table, Tr, Th, Tbody, Td} from '@chakra-ui/react';

import {Delta} from '@atoms/Delta';
import {Text} from '@atoms/Text';

import {IProps} from './interfaces';

const HospitalTable = ({
  allPatients,
  singleDelta,
  allDelta,
  hoursEv,
  hoursSc,
  costEv,
  costSc,
  hoursToday,
  hoursTomorrow,
  costToday,
  costTomorrow,
}: IProps) => {
  return (
    <Box w={445} position="relative">
      <Table variant="simple" w={320}>
        <Tbody>
          <Tr>
            <Th px={0} w="25%">
              <Text
                color="common.black"
                textTransform="capitalize"
                textAlign="right">
                Ore totali
              </Text>
            </Th>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {allPatients ? hoursToday : hoursEv}
              </Text>
            </Td>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {allPatients ? hoursTomorrow : hoursSc}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Th px={0} w="25%">
              <Text
                color="common.black"
                textTransform="capitalize"
                textAlign="right">
                Costo personale
              </Text>
            </Th>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {allPatients ? costToday : costEv}
              </Text>
            </Td>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {allPatients ? costTomorrow : costSc}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {allPatients && allDelta !== '' && <Delta delta={allDelta} />}
      {!allPatients && singleDelta !== '' && <Delta delta={singleDelta} />}
    </Box>
  );
};

export default HospitalTable;
