import {decimalToPercentage, percentageToDecimal} from '@utils/math';
import {
  FilledScenariosData,
  PatientData,
  Roles,
  Scenarios,
  StaffData,
  StaffDataInfo,
  StaffWage,
  Therapies,
} from '@definitions';
import {staffDataSubset} from '@config';

import {
  HospitalResultsForAllPatients,
  ScenariosResultsPerYear,
  HospitalResultsForSinglePatient,
  StaffResultsPerYear,
  SinglePatientDeltaResults,
} from './definitions';

export const getActivitiesHoursPerYear = (
  staffData: StaffData,
  dosingCount: number,
  therapy: Therapies,
  role: Roles,
) => {
  const keys = Object.keys(staffData) as (keyof StaffData)[];

  const filteredKeys = keys.filter(key => staffDataSubset.includes(key));

  const sum = filteredKeys.reduce((acc, currentKey) => {
    const roleMatches = staffData[currentKey].role === role;
    const value = staffData[currentKey][therapy];

    if (roleMatches && value !== null) {
      acc += value;
    }

    return acc;
  }, 0);

  return sum * dosingCount;
};

export const getSingleActivityHoursPerYear = (
  activityTimeData: StaffDataInfo,
  yearMultiplier: number,
  therapy: Therapies,
  role: Roles,
) => {
  if (activityTimeData.role !== role) return 0;

  return (activityTimeData[therapy] || 0) * yearMultiplier;
};

export const getAllActivitiesHoursPerYear = (
  data: StaffData,
  role: Roles,
  therapy: Therapies,
  dosingCount: number,
  checkupCount: number,
  therapyPickupCount: number,
  checkupIncluded: boolean,
) => {
  // 1. somma delle attività legate alla figura professionale) * numero somministrazioni
  // +
  // 2. visita controllo inclusa && visita controllo fatta dalla figura professionale ? (tempo visita controllo * numero visite controllo) : 0
  // +
  // 3. visita controllo inclusa && accettazione fatta dalla figura professionale ? (tempo accettazione * numero visite controllo) : 0
  // tutto diviso 60

  if (therapy === 'ev') {
    let sum1 = getActivitiesHoursPerYear(data, dosingCount, therapy, role);
    let sum2 = 0;
    let sum3 = 0;

    if (checkupIncluded) {
      sum2 = getSingleActivityHoursPerYear(
        data.checkupTime,
        checkupCount,
        therapy,
        role,
      );

      sum3 = getSingleActivityHoursPerYear(
        data.acceptanceTime,
        checkupCount,
        therapy,
        role,
      );
    }

    return (sum1 + sum2 + sum3) / 60;
  }

  // 1. visita controllo inclusa && visita controllo fatta dalla figura professionale ? (tempo visita controllo * numero visite controllo) : 0
  // +
  // 2. visita controllo inclusa && accettazione fatta dalla figura professionale ? (tempo accettazione * numero visite controllo) : 0
  // +
  // 3. ritiro terpaia in farmacia fatta dalla figura professionale ? (tempo ritiro * numero ritiri) : 0
  // tutto diviso 60
  else {
    let sum1 = 0;
    let sum2 = 0;

    if (checkupIncluded) {
      sum1 = getSingleActivityHoursPerYear(
        data.checkupTime,
        checkupCount,
        therapy,
        role,
      );

      sum2 = getSingleActivityHoursPerYear(
        data.acceptanceTime,
        checkupCount,
        therapy,
        role,
      );
    }

    let sum3 = getSingleActivityHoursPerYear(
      data.therapyPickupTime,
      therapyPickupCount,
      therapy,
      role,
    );

    return (sum1 + sum2 + sum3) / 60;
  }
};

export const getAllActivitiesCostPerYear = (
  hoursPerYear: number,
  wagePerMin: number,
) => {
  return hoursPerYear * 60 * wagePerMin;
};

export const getStaffResultsPerYear = (
  staffData: StaffData,
  patientData: PatientData,
  staffWage: StaffWage,
  checkupIncluded: boolean,
): StaffResultsPerYear => {
  let result = {} as StaffResultsPerYear;
  const roles: Roles[] = ['administrative', 'pharmacist', 'nurse', 'doctor'];
  const therapies: Therapies[] = ['ev', 'sc'];

  therapies.forEach(therapy => {
    result[therapy] = {} as StaffResultsPerYear[keyof StaffResultsPerYear];

    let rolesTotalHours = 0;
    let rolesTotalCost = 0;

    roles.forEach(role => {
      const dosingCount = patientData.dosingCount[therapy] || 0;
      const checkupCount = patientData.checkupCount[therapy] || 0;
      const therapyPickupCount = patientData.therapyPickupCount[therapy] || 0;

      const hoursPerYear = getAllActivitiesHoursPerYear(
        staffData,
        role,
        therapy,
        dosingCount,
        checkupCount,
        therapyPickupCount,
        checkupIncluded,
      );

      const costPerYear = getAllActivitiesCostPerYear(
        hoursPerYear,
        staffWage[role],
      );

      result[therapy][role] = {
        hoursPerYear,
        costPerYear,
        // Temporary set to zero, calculated after
        hoursPercent: 0,
      };

      rolesTotalHours += hoursPerYear;
      rolesTotalCost += costPerYear;
    });

    result[therapy]['total'] = {
      hoursPerYear: rolesTotalHours,
      costPerYear: rolesTotalCost,
    };

    // Calculate single percentages
    roles.forEach(role => {
      result[therapy][role].hoursPercent =
        (result[therapy][role].hoursPerYear * 100) /
        result[therapy].total.hoursPerYear;
    });
  });

  return result;
};

const getTherapiesDeltaValues = (
  data: StaffResultsPerYear,
): SinglePatientDeltaResults => {
  const totalEvHours = data.ev.total.hoursPerYear;
  const totalScHours = data.sc.total.hoursPerYear;
  const totalEvCost = data.ev.total.costPerYear;
  const totalScCost = data.sc.total.costPerYear;

  return {
    hours: totalScHours - totalEvHours,
    hoursPercent: decimalToPercentage(
      (totalScHours - totalEvHours) / totalEvHours,
    ),
    cost: totalScCost - totalEvCost,
    costPercent: decimalToPercentage((totalScCost - totalEvCost) / totalEvCost),
  };
};

export const getHospitalResultsForSinglePatient = (
  staffData: StaffData,
  patientData: PatientData,
  staffWage: StaffWage,
  checkupIncluded: boolean,
): HospitalResultsForSinglePatient => {
  const staffResults = getStaffResultsPerYear(
    staffData,
    patientData,
    staffWage,
    checkupIncluded,
  );

  return {
    ...staffResults,
    delta: getTherapiesDeltaValues(staffResults),
  };
};

export const getHoursPerYear = (
  hours: number,
  patientsPercentage: number,
  patientsCount: number,
) => {
  return hours * percentageToDecimal(patientsPercentage) * patientsCount;
};

export const getScenariosResultsPerYear = (
  singlePatientResults: StaffResultsPerYear,
  scenarios: FilledScenariosData,
  patientsCount: number,
): ScenariosResultsPerYear => {
  let result = {} as ScenariosResultsPerYear;
  const scenariosKeys = Object.keys(scenarios) as Scenarios[];

  scenariosKeys.forEach(scenario => {
    let data = {} as ScenariosResultsPerYear[keyof ScenariosResultsPerYear];
    const therapies: Therapies[] = ['ev', 'sc'];

    let totalHours = 0;
    let totalCost = 0;

    therapies.forEach(therapy => {
      const hours = getHoursPerYear(
        singlePatientResults[therapy].total.hoursPerYear,
        scenarios[scenario][therapy],
        patientsCount,
      );

      data[therapy] = {
        hours,
        // Temporary set to zero, calculated after
        hoursPercent: 0,
      };

      totalCost +=
        singlePatientResults[therapy].total.costPerYear *
        patientsCount *
        percentageToDecimal(scenarios[scenario][therapy]);

      totalHours += hours;
    });

    data['total'] = {
      hours: totalHours,
      cost: totalCost,
    };

    result[scenario] = data;

    // Calculate single percentages
    therapies.forEach(therapy => {
      result[scenario][therapy].hoursPercent =
        (result[scenario][therapy].hours * 100) / result[scenario].total.hours;
    });
  });

  return result;
};

export const getScenariosDeltaValues = (data: ScenariosResultsPerYear) => {
  const hoursPercent = decimalToPercentage(
    (data.tomorrow.total.hours - data.today.total.hours) /
      data.today.total.hours,
  );

  return {
    hoursPercent,
    cost: data.tomorrow.total.cost - data.today.total.cost,
  };
};

export const getHospitalResultsForAllPatients = (
  singlePatientResults: StaffResultsPerYear,
  scenarios: FilledScenariosData,
  patientsCount: number,
): HospitalResultsForAllPatients => {
  const scenariosResults = getScenariosResultsPerYear(
    singlePatientResults,
    scenarios,
    patientsCount,
  );

  return {
    ...scenariosResults,
    delta: getScenariosDeltaValues(scenariosResults),
  };
};
