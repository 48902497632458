import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';

import {useInputDataContext} from '@contexts/inputData';
import {useSectionsContext} from '@contexts/sections';
import {Subsection} from '@contexts/sections/definitions';
import FirstStep from '@organisms/inputSection/firstStep/firstStep';
import SecondStep from '@organisms/inputSection/secondStep/secondStep';
import validationSchema from '@organisms/inputSection/validationSchema';
import {PatientData, StaffData} from '@definitions';

import {Fields} from './definitions';

const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
  },
};

const InputSection = () => {
  const {
    patientData,
    staffData,
    updatePatientData,
    updateStaffData,
    setValidationErrors,
  } = useInputDataContext();
  const {activeSubsection} = useSectionsContext();

  const {
    register,
    watch,
    formState: {errors},
    trigger,
    control,
  } = useForm<Fields>({
    defaultValues: {
      ...patientData?.current,
      ...staffData?.current,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  // Listen to field values changes
  // - Update context values on field value change
  // - Trigger cross field validation
  useEffect(() => {
    const subscription = watch(data => {
      const patientValues = {} as PatientData;
      const staffValues = {} as StaffData;
      const keys = Object.keys(data) as (keyof Fields)[];

      keys.forEach(key => {
        if (key in patientData.current) {
          // @ts-ignore
          patientValues[key] = data[key];
        }

        if (key in staffData.current) {
          // @ts-ignore
          staffValues[key] = data[key];
        }
      });

      // Update context values
      updatePatientData(patientValues);
      updateStaffData(staffValues);

      // Trigger validation of entire form, for dependant field validation
      trigger();
    });

    // Unsubscribe in useEffect cleanup function
    return () => subscription.unsubscribe();
  }, [
    patientData,
    staffData,
    trigger,
    updatePatientData,
    updateStaffData,
    watch,
  ]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(undefined);
    }
  }, [errors, setValidationErrors]);

  return (
    <Box sx={styles.container}>
      {activeSubsection === Subsection.FIRST_STEP && (
        <FirstStep register={register} control={control} errors={errors} />
      )}
      {activeSubsection === Subsection.SECOND_STEP && (
        <SecondStep register={register} control={control} errors={errors} />
      )}
    </Box>
  );
};

export default React.memo(InputSection);
