import {Control, FieldErrors, UseFormRegister} from 'react-hook-form';
import {Flex, Image, Grid, GridItem} from '@chakra-ui/react';

import {ControlledNumberInput} from '@organisms/inputSection/controlledNumberInput';
import {Fields} from '@organisms/inputSection/definitions';
import {Text} from '@atoms/Text';
import graffa from '@theme/input_graffa.png';

import ErrorPopUp from './errorPopUp';

const FirstStepGrid = ({
  control,
  errors,
}: {
  register: UseFormRegister<Fields>;
  control: Control<Fields>;
  errors: FieldErrors<Fields>;
}) => {
  return (
    <>
      <Grid
        width={400}
        height="220px"
        position="relative"
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(5, 1fr)"
        alignItems="center"
        textAlign="right"
        ml="-15%"
        gap={2}>
        <GridItem rowSpan={1} colSpan={5} justifySelf="center">
          <Text
            variant="titleLarge"
            color="neutral.200"
            textTransform="uppercase">
            Attività paziente per anno
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={3} />
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text variant="bodyMedium">EV</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text variant="bodyMedium">SC</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
          {(errors.dosingCount?.ev || errors.dosingCount?.sc) && <ErrorPopUp />}
        </GridItem>
        <GridItem rowSpan={1} colSpan={2}>
          <Text>N° somministrazioni</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <ControlledNumberInput
            name="dosingCount.ev"
            control={control}
            isInvalid={Boolean(errors.dosingCount?.ev)}
            max={13}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <ControlledNumberInput
            name="dosingCount.sc"
            control={control}
            isInvalid={Boolean(errors.dosingCount?.sc)}
            max={52}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
          {errors.therapyPickupCount?.sc && <ErrorPopUp placement="bottom" />}
        </GridItem>
        <GridItem rowSpan={1} colSpan={2}>
          <Text>N° ritiri terapia in farmacia</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <ControlledNumberInput
            name="therapyPickupCount.sc"
            control={control}
            isInvalid={Boolean(errors.therapyPickupCount?.sc)}
            max={13}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
          {(errors.checkupCount?.ev || errors.checkupCount?.sc) && (
            <ErrorPopUp placement="right" />
          )}
        </GridItem>
        <GridItem rowSpan={1} colSpan={2}>
          <Text>N° visite di controllo</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <ControlledNumberInput
            name="checkupCount.ev"
            control={control}
            isInvalid={Boolean(errors.checkupCount?.ev)}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <ControlledNumberInput
            name="checkupCount.sc"
            control={control}
            isInvalid={Boolean(errors.checkupCount?.sc)}
          />
        </GridItem>
      </Grid>
      <Flex position="relative" w="100%" justifyContent="center" ml="-15%">
        <Grid
          width={430}
          height="220px"
          templateRows="repeat(5, 1fr)"
          templateColumns="repeat(5, 1fr)"
          textAlign="right"
          alignItems="center"
          gap={2}>
          <GridItem rowSpan={1} colSpan={5} justifySelf="center">
            <Text
              variant="titleLarge"
              color="neutral.200"
              textTransform="uppercase"
              mb={7}>
              Tempo di gestione del paziente in ospedale
            </Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} />
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text fontWeight="bold">EV</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text fontWeight="bold">SC</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
            {errors.totalPermanenceTime?.ev && <ErrorPopUp />}
          </GridItem>
          <GridItem rowSpan={1} colSpan={2}>
            <Text>Tempo totale di permanenza</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <ControlledNumberInput
              name="totalPermanenceTime.ev"
              control={control}
              isInvalid={Boolean(errors.totalPermanenceTime?.ev)}
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
            {errors.chairOccupationTime?.ev && <ErrorPopUp />}
          </GridItem>
          <GridItem rowSpan={1} colSpan={2}>
            <Text>
              Tempo di occupazione poltrona (infusione + osservazione)
            </Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <ControlledNumberInput
              name="chairOccupationTime.ev"
              control={control}
              isInvalid={Boolean(errors.chairOccupationTime?.ev)}
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={1} mt={54} justifySelf="flex-end">
            {(errors.checkupTotalPermanenceTime?.ev ||
              errors.checkupTotalPermanenceTime?.sc) && <ErrorPopUp />}
          </GridItem>
          <GridItem rowSpan={1} colSpan={2} mt={54}>
            <Text>Visita di controllo: tempo totale di permanenza</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center" mt={54}>
            <ControlledNumberInput
              name="checkupTotalPermanenceTime.ev"
              control={control}
              isInvalid={Boolean(errors.checkupTotalPermanenceTime?.ev)}
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center" mt={54}>
            <ControlledNumberInput
              name="checkupTotalPermanenceTime.sc"
              control={control}
              isInvalid={Boolean(errors.checkupTotalPermanenceTime?.sc)}
            />
          </GridItem>
        </Grid>
        <Flex
          position="absolute"
          alignItems="center"
          right="10px"
          gap={3}
          top="90px">
          <Image src={graffa} height={95} />
          <Text color="primary.main" textTransform="uppercase" w="130px">
            Giorno della somministrazione
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default FirstStepGrid;
