import {useMemo} from 'react';
import {useAuth} from 'react-oidc-context';
import {Box, Image, HStack, Spinner, VStack} from '@chakra-ui/react';

import {Text} from '@atoms/Text';
import logo from '@theme/assets/icons/GSK-white.svg';

import {styles} from './styles';

const Login = () => {
  const auth = useAuth();

  const renderContent = useMemo(() => {
    if (auth.isLoading) {
      return (
        <HStack spacing={2}>
          <Spinner size="md" speed="0.6s" color="common.white" />
          <Text variant="bodyMedium" color="common.white">
            Caricamento...
          </Text>
        </HStack>
      );
    }

    if (auth.error) {
      return (
        <Box>
          <Text variant="bodyMedium" color="common.white">
            Oops... {auth.error.message}
          </Text>
        </Box>
      );
    }

    return (
      <VStack spacing={8}>
        <Image src={logo} w={245} />
        <Box sx={styles.button} onClick={() => void auth.signinRedirect()}>
          <Text
            variant="titleLarge"
            color="common.black"
            textTransform="uppercase">
            Accedi
          </Text>
        </Box>
      </VStack>
    );
  }, [auth]);

  return <Box sx={styles.container}>{renderContent}</Box>;
};

export default Login;
