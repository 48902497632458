import {IPatientsResults} from '@atoms/PatientResultsGraphic/interfaces';
import {
  PatientResultsForAllPatients,
  PatientResultsForSinglePatient,
} from '@utils/formulas/patient/definitions';
import {round} from '@utils/math';
import {theme} from '@theme/theme';

export const getSinglePatientChartData = (
  results: PatientResultsForSinglePatient,
): IPatientsResults[] => {
  return [
    {
      name: 'Attività',
      color: theme.colors.chars.Activity,
      values: [results.ev.activity.hours, results.sc.activity.hours],
      percents: [
        round(results.ev.activity.percent),
        round(results.sc.activity.percent),
      ],
    },
    {
      name: 'Attesa',
      color: theme.colors.chars.Waiting,
      values: [results.ev.waiting.hours, results.sc.waiting.hours],
      percents: [
        round(results.ev.waiting.percent),
        round(results.sc.waiting.percent),
      ],
    },
    {
      name: 'Spostamento',
      color: theme.colors.chars.Moving,
      values: [results.ev.moving.hours, results.sc.moving.hours],
      percents: [
        round(results.ev.moving.percent),
        round(results.sc.moving.percent),
      ],
    },
  ];
};

export const getAllPatientChartData = (
  results: PatientResultsForAllPatients,
): IPatientsResults[] => {
  return [
    {
      name: 'Paziente EV',
      color: theme.colors.chars.Ev,
      values: [
        results.detailHours.today.patientEv.hours,
        results.detailHours.tomorrow.patientEv.hours,
      ],
      percents: [
        round(results.detailHours.today.patientEv.percent),
        round(results.detailHours.tomorrow.patientEv.percent),
      ],
    },
    {
      name: 'Caregiver EV',
      color: theme.colors.chars.EvLight,
      values: [
        results.detailHours.today.caregiverEv.hours,
        results.detailHours.tomorrow.caregiverEv.hours,
      ],
      percents: [
        round(results.detailHours.today.caregiverEv.percent),
        round(results.detailHours.tomorrow.caregiverEv.percent),
      ],
    },
    {
      name: 'Paziente SC',
      color: theme.colors.chars.Sc,
      values: [
        results.detailHours.today.patientSc.hours,
        results.detailHours.tomorrow.patientSc.hours,
      ],
      percents: [
        round(results.detailHours.today.patientSc.percent),
        round(results.detailHours.tomorrow.patientSc.percent),
      ],
    },
    {
      name: 'Caregiver SC',
      color: theme.colors.chars.ScLight,
      values: [
        results.detailHours.today.caregiverSc.hours,
        results.detailHours.tomorrow.caregiverSc.hours,
      ],
      percents: [
        round(results.detailHours.today.caregiverSc.percent),
        round(results.detailHours.tomorrow.caregiverSc.percent),
      ],
    },
  ];
};
