import {defineStyleConfig} from '@chakra-ui/react';

import {FontFamily, FontSize, LineHeight} from './variables';

export const typography = defineStyleConfig({
  variants: {
    headlineLarge: {
      fontSize: FontSize.HeadlineLarge,
      lineHeight: LineHeight.HeadlineLarge,
      fontFamily: FontFamily.Regular,
    },
    headlineSmall: {
      fontSize: FontSize.HeadlineSmall,
      lineHeight: LineHeight.HeadlineSmall,
      fontFamily: FontFamily.Bold,
    },
    titleLarge: {
      fontSize: FontSize.TitleLarge,
      lineHeight: LineHeight.TitleLarge,
      fontFamily: FontFamily.Bold,
    },
    titleSmall: {
      fontSize: FontSize.TitleSmall,
      lineHeight: LineHeight.TitleSmall,
      fontFamily: FontFamily.Regular,
    },
    bodyMedium: {
      fontSize: FontSize.BodyMedium,
      lineHeight: LineHeight.BodyMedium,
      fontFamily: FontFamily.Bold,
    },
    bodySmall: {
      fontSize: FontSize.BodySmall,
      lineHeight: LineHeight.BodySmall,
      fontFamily: FontFamily.Regular,
    },
    bodySmallBold: {
      fontSize: FontSize.BodySmall,
      lineHeight: LineHeight.BodySmall,
      fontFamily: FontFamily.Bold,
    },
    bodyXSmall: {
      fontSize: FontSize.BodyXSmall,
      lineHeight: LineHeight.BodyXSmall,
      fontFamily: FontFamily.Bold,
    },
  },
});
