import {
  Box,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

import {Text} from '@atoms/Text';

export const usePopUpMessages = () => {
  const hypothesis = (
    <Box>
      <Text
        variant="bodySmallBold"
        textTransform="uppercase"
        color="primary.main">
        ipotesi alla base del modello
      </Text>
      <UnorderedList>
        <ListItem>
          <Text>
            Il tool è alimentato con valori di input rappresentati della realtà
            dei pazienti.
          </Text>
        </ListItem>
        <ListItem>
          <Text>Per misurare l'impatto delle diverse terapie:</Text>
          <OrderedList>
            <ListItem>
              <Text>
                Sono state considerate le seguenti fasi/attività del percorso
                paziente:
              </Text>
            </ListItem>
            <UnorderedList>
              <ListItem>
                <Text>EV:</Text>
                <UnorderedList listStyleType="none">
                  <ListItem>
                    <Text>- Accessi per somministrazione EV in ospedale</Text>
                  </ListItem>
                  <ListItem>
                    <Text>- Accessi per visite di controllo in ospedale</Text>
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                <Text>SC</Text>
                <UnorderedList listStyleType="none">
                  <ListItem>
                    <Text>- Somministratione terapia SC a domicilio</Text>
                  </ListItem>
                  <ListItem>
                    <Text>- Accessi per ritiro terapia SC in farmacia</Text>
                  </ListItem>
                  <ListItem>
                    <Text>- Accessi per visite di controllo in ospedale</Text>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
            <ListItem>
              <Text>
                È stato tenuto conto delle seguenti voci di costo nella
                prospettiva:
              </Text>
              <UnorderedList>
                <ListItem>
                  <Text>Ospedaliera: costo del personale ospedaliero</Text>
                </ListItem>
                <ListItem>
                  <Text>Paziente: costi sociali indiretti</Text>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </OrderedList>
        </ListItem>
      </UnorderedList>
    </Box>
  );

  const sources = (
    <Box w={620}>
      <Text
        variant="bodySmallBold"
        textTransform="uppercase"
        color="primary.main">
        Fonti
      </Text>
      <Text>
        Di seguito vengono riportate le assunzioni sui dati non modificabili
        alla base dei:
      </Text>
      <Text variant="bodySmallBold" mt="20px">
        Costi del personale ospedaliero
      </Text>
      <OrderedList>
        <ListItem>
          <Text>
            RAL Clinico: 85.607 €/anno (
            <Link
              color="link.main"
              isExternal
              href="https://www.aranagenzia.it/attachments/article/5152/Retribuzioni%20medie%20PA%20per%20macrovoce_SITO.pdf">
              ARAN 2014, Retribuzioni medie nella pubblica amministrazione
            </Link>
            )
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            RAL Infermiere: 36.330 €/anno (
            <Link
              color="link.main"
              isExternal
              href="https://osservatoriocpi.unicatt.it/ocpi-pubblicazioni-il-personale-socio-sanitario-un-confronto-europeo#:~:text=In%20base%20ai%20dati%20OCSE,6">
              OCSE 2020
            </Link>
            )
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            RAL Farmacista: 53.947 €/anno (
            <Link
              color="link.main"
              isExternal
              href="https://farmacistiallavoro.it/2017/03/06/lo-stipendio-del-farmacista-ospedaliero/">
              PGEU 2015
            </Link>
            )
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            RAL Amministrativo: 27.041 €/anno (
            <Link
              color="link.main"
              isExternal
              href="https://www.aranagenzia.it/attachments/article/5152/Retribuzioni%20medie%20PA%20per%20macrovoce_SITO.pdf">
              ARAN 2014, Retribuzioni medie nella pubblica amministrazione
            </Link>
            )
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="bodySmallBold" mt="20px">
        Costi del personale ospedaliero
      </Text>
      <OrderedList start={5}>
        <ListItem>
          <Text>
            RAL Paziente/caregiver: 32.812 €/anno (
            <Link
              color="link.main"
              isExternal
              href="https://www.istat.it/it/files/2022/10/Condizioni-di-vita-e-reddito-delle-famiglie-2020-2021.pdf">
              ISTAT 2021, Condizioni di vita e reddito delle famiglie
            </Link>
            )
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="bodySmallBold" mt="20px">
        Tempi SC
      </Text>
      <OrderedList start={6}>
        <ListItem>
          <Text>Tempo di somministrazione terapia SC al domicilio: 3 min</Text>
        </ListItem>
        <ListItem>
          <Text>Tempo di attesa per ritiro terapia SC in farmacia: 0 min</Text>
        </ListItem>
      </OrderedList>
      <Text variant="bodySmallBold" mt="20px">
        Generali
      </Text>
      <OrderedList start={8}>
        <ListItem>
          <Text>Giorni lavorativi/anno: 220gg/anno</Text>
        </ListItem>
        <ListItem>
          <Text> Ore lavorative/giorno: 8 ore/gg</Text>
        </ListItem>
      </OrderedList>
      <Text mt="20px">
        I valori di input modificabili dall’utente sono stati precompilati
        ipotizzando il percorso di un paziente LES in terapia con belimumab EV o
        SC e facendo riferimento al{' '}
        <Link
          color="link.main"
          isExternal
          href="https://www.ema.europa.eu/en/documents/product-information/benlysta-epar-product-information_it.pdf">
          RCP di Benlysta
        </Link>
      </Text>
    </Box>
  );

  const acronyms = (
    <Box>
      <Text
        variant="bodySmallBold"
        textTransform="uppercase"
        color="primary.main"
        mb="15px">
        Acronimi
      </Text>
      <Text variant="bodySmallBold">EV: Terapia Endovenosa</Text>
      <Text variant="bodySmallBold">SC: Terapia Sottocutanea</Text>
      <Text variant="bodySmallBold">RAL: Reddito Annuale Lordo</Text>
    </Box>
  );

  return {hypothesis, sources, acronyms};
};
