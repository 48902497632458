import * as yup from 'yup';
import {ObjectSchema} from 'yup';

import {Roles} from '@definitions';

import {Fields} from './definitions';

const firstCrossCheckFields = [
  'totalPermanenceTime',
  'chairOccupationTime',
  'acceptanceTime',
  'preDosingVisitTime',
];

const secondCrossCheckFields = [
  'chairOccupationTime',
  'infusionObservationTime',
];

const thirdCrossCheckFields = [
  'checkupTotalPermanenceTime',
  'acceptanceTime',
  'checkupTime',
];

const defaultValidation = yup
  .number()
  .typeError('Deve essere un numero')
  .positive('Deve essere un numero positivo')
  .integer('Deve essere un numero intero')
  .defined()
  .nullable();

const evValidation = defaultValidation
  .test(
    'first-crosscheck',
    'La somma dei tempi delle singole attività non può superare il tempo totale di permanenza del paziente in ospedale',
    (value, context) => {
      const parentField = context.path.split('.')[0];

      if (firstCrossCheckFields.includes(parentField)) {
        const {
          totalPermanenceTime,
          chairOccupationTime,
          acceptanceTime,
          preDosingVisitTime,
        } = context.from![1].value;

        return (
          totalPermanenceTime.ev >=
          chairOccupationTime.ev + acceptanceTime.ev + preDosingVisitTime.ev
        );
      }

      return true;
    },
  )
  .test(
    'second-crosscheck',
    'Il tempo di occupazione poltrona del paziente non può essere inferiore a quello dedicato dal personale ospedaliero',
    (value, context) => {
      const parentField = context.path.split('.')[0];

      if (secondCrossCheckFields.includes(parentField)) {
        const {chairOccupationTime, infusionObservationTime} =
          context.from![1].value;

        return chairOccupationTime.ev >= infusionObservationTime.ev;
      }

      return true;
    },
  )
  .test(
    'third-crosscheck',
    'Il tempo della visita di controllo per il personale ospedaliero non può superare il tempo totale di permanenza del paziente in ospedale',
    (value, context) => {
      const parentField = context.path.split('.')[0];

      if (thirdCrossCheckFields.includes(parentField)) {
        const {checkupTotalPermanenceTime, acceptanceTime, checkupTime} =
          context.from![1].value;

        return (
          checkupTotalPermanenceTime.ev >= acceptanceTime.ev + checkupTime.ev
        );
      }

      return true;
    },
  );

const scValidation = defaultValidation.test(
  'third-crosscheck',
  'Il tempo della visita di controllo per il personale ospedaliero non può superare il tempo totale di permanenza del paziente in ospedale',
  (value, context) => {
    const parentField = context.path.split('.')[0];

    if (thirdCrossCheckFields.includes(parentField)) {
      const {checkupTotalPermanenceTime, acceptanceTime, checkupTime} =
        context.from![1].value;

      return (
        checkupTotalPermanenceTime.sc >= acceptanceTime.sc + checkupTime.sc
      );
    }

    return true;
  },
);

const defaultPatientValidation: ObjectSchema<{
  ev: number | null;
  sc: number | null;
}> = yup.object().shape({
  ev: evValidation,
  sc: scValidation,
});

const defaultStaffValidation = yup.object().shape({
  ev: evValidation,
  sc: scValidation,
  role: yup
    .mixed<Roles>()
    .oneOf(['administrative', 'pharmacist', 'nurse', 'doctor'])
    .required(),
});

const validationSchema: ObjectSchema<Fields> = yup
  .object()
  .shape({
    dosingCount: defaultPatientValidation,
    therapyPickupCount: defaultPatientValidation,
    checkupCount: defaultPatientValidation,
    totalPermanenceTime: defaultPatientValidation,
    chairOccupationTime: defaultPatientValidation,
    checkupTotalPermanenceTime: defaultPatientValidation,
    acceptanceTime: defaultStaffValidation,
    preDosingVisitTime: defaultStaffValidation,
    therapyDeliveryTime: defaultStaffValidation,
    medicinePreparationTime: defaultStaffValidation,
    infusionObservationTime: defaultStaffValidation,
    therapyPickupTime: defaultStaffValidation,
    checkupTime: defaultStaffValidation,
  })
  .required();

export default validationSchema;
