import {Flex, Grid, GridItem} from '@chakra-ui/react';

import {CustomNumberInput} from '@atoms/inputNumber';
import {Text} from '@atoms/Text';

import InfoPopUp from './infoPopUp';
import {IProps} from './interface';

const InfusionCenterGrid = ({
  todaySc,
  todayEv,
  tomorrowSc,
  tomorrowEv,
  patients,
  averageTime,
}: IProps) => {
  return (
    <Flex
      bg="neutral.100"
      height="293px"
      width="315px"
      justifyContent="center"
      alignItems="center">
      <Grid
        height="225px"
        width="280px"
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(5, 1fr)"
        alignItems="center"
        gap={2}>
        <GridItem rowSpan={1} colSpan={2} />
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text variant="bodyMedium">SC</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text variant="bodyMedium">EV</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={2} textAlign="right">
          <Text>OGGI</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <CustomNumberInput {...todaySc} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <CustomNumberInput {...todayEv} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text>%</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={2} textAlign="right">
          <Text>DOMANI</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <CustomNumberInput {...tomorrowSc} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <CustomNumberInput {...tomorrowEv} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text>%</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem
          rowSpan={1}
          colSpan={2}
          justifySelf="center"
          textAlign="right">
          <Text>N° pazienti in trattamento</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <CustomNumberInput {...patients} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem
          rowSpan={1}
          colSpan={3}
          display="flex"
          justifySelf="center"
          textAlign="right">
          <InfoPopUp />
          <Text>Duarata media infusione per un paziente medio del reparto</Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <CustomNumberInput {...averageTime} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} justifySelf="center">
          <Text>min</Text>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default InfusionCenterGrid;
