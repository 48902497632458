import {useMemo} from 'react';
import ReactEcharts from 'echarts-for-react';
import {Box} from '@chakra-ui/react';

import {Delta} from '@atoms/Delta';
import {round} from '@utils/math';
import {formatNumber} from '@utils/string';
import {theme} from '@theme/theme';

import {PatientChartProps} from './interfaces';

const PatientResultsGraphic = ({name, data, delta}: PatientChartProps) => {
  //Map over data
  const series = useMemo(
    () =>
      data.map(elem => ({
        name: elem.name,
        type: 'bar',
        stack: 'Column',
        legendHoverLink: false,
        silent: true,
        itemStyle: {
          color: elem.color,
          borderRadius: [4, 4, 4, 4],
          borderWidth: 0.5,
          borderColor: theme.colors.common.white,
        },
        emphasis: {
          focus: 'series',
        },
        data: elem.values,
        label: {
          show: true,
          formatter: (params: {dataIndex: number}) => {
            const percentValue = elem.percents[params.dataIndex];
            return percentValue === 0 ? '' : `${percentValue}%`;
          },
          color: theme.colors.common.white,
          fontWeight: 'bold',
        },
      })),
    [data],
  );

  const option = {
    tooltip: {},
    legend: {
      selectedMode: false,
      orient: 'vertical',
      right: 0,
      top: 20,
    },
    barCategoryGap: 14,
    xAxis: {
      type: 'category',
      data: name,
      axisTick: {show: false},
      axisLine: {show: true},
      axisLabel: {
        verticalAlign: 'top',
        padding: [-370, 0],
        fontWeight: 'bold',
        color: theme.colors.common.black,
      },
    },
    yAxis: {
      type: 'value',
      name: 'h/anno',
      nameLocation: 'start',
      nameGap: -8,
      nameTextStyle: {
        align: 'right',
        padding: [0, 10],
      },
      splitLine: {show: false},
      axisTick: {show: true},
      axisLine: {show: true},
      axisLabel: {
        show: true,
        formatter: (value: number) => (value === 0 ? null : value),
      },
    },
    grid: {
      width: '60%',
      left: '15%',
      right: '10%',
      bottom: 10,
      top: 30,
    },
    series: series,
  };
  return (
    <Box
      width="-webkit-fit-content"
      height="-webkit-fit-content"
      position="relative">
      <ReactEcharts
        option={option}
        opts={{renderer: 'svg'}}
        notMerge={true}
        style={{width: '425px', height: '380px'}}
      />
      {delta && (
        <Delta
          delta={`${formatNumber(round(delta), {
            signDisplay: 'exceptZero',
          })}%`}
          right={-5}
        />
      )}
    </Box>
  );
};

export default PatientResultsGraphic;
