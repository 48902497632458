import {Box} from '@chakra-ui/react';

import {Content} from '@organisms/content';
import {Menu} from '@molecules/menu';

import {styles} from './styles';

const Main = () => {
  return (
    <Box sx={styles.container}>
      <Menu />
      <Content />
    </Box>
  );
};

export default Main;
