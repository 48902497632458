import {StylesObject} from '@definitions';
import {theme} from '@theme/theme';

export const styles: StylesObject = {
  container: {
    padding: '20px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
    backgroundColor: theme.colors.neutral[50],
  },

  listItem: {
    color: theme.colors.neutral[200],
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px',
  },

  subItem__active: {
    position: 'relative',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px',
    backgroundColor: theme.colors.neutral[200],
    color: theme.colors.common.white,

    '& img': {
      position: 'absolute',
      right: 0,
      height: '100%',
    },
  },

  listItem__active: {
    position: 'relative',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px',
    backgroundColor: theme.colors.neutral[100],
    color: theme.colors.primary.main,
    borderBottom: `2px solid ${theme.colors.primary.main}`,

    '& img': {
      position: 'absolute',
      right: 0,
      height: '100%',
    },
  },
};
