import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
} from '@chakra-ui/react';

import {IProps} from './interfaces';

const PopUp = ({trigger, message, placement, mx = 6, my = 6}: IProps) => {
  return (
    <Popover arrowSize={25} placement={placement}>
      <PopoverTrigger>
        <Box>{trigger}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          rootProps={{style: {transform: 'scale(0)'}}}
          bg="neutral.100"
          mx={mx}
          my={my}
          width="fit-content"
          _focusVisible={{outline: 0}}>
          <PopoverArrow bg="neutral.100" />
          <PopoverBody _focusVisible={{outline: 0}}>{message}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default PopUp;
