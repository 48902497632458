import {
  Box,
  ListItem,
  OrderedList,
  SimpleGrid,
  UnorderedList,
} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';

import {IPopUp} from './interfaces';

const InfoPopUp = ({allPatients}: IPopUp) => {
  const InfusionText = (
    <SimpleGrid columns={2} spacing={10} w={625} h="fit-content">
      <Box>
        {allPatients ? (
          <Text mb="20px">
            Il grafico mostra il tempo di tutti i pazienti e relativi caregiver
            dedicato alla gestione della propria patologia in un anno in
            trattamento con una terapia EV o SC considerando le percentuali dei
            due diversi trattamenti negli scenari «Oggi» e «Domani».
          </Text>
        ) : (
          <Text mb="20px">
            Il grafico mostra il tempo di un paziente dedicato alla gestione
            della propria patologia in un anno in trattamento con un farmaco EV
            o SC.
          </Text>
        )}
        <Text>
          Considerando la prospettiva del paziente, sono stati considerati i
          tempi di:
        </Text>
        <UnorderedList mb="20px">
          <ListItem>
            <Text>Attività legate alla cura del paziente</Text>
          </ListItem>
          <ListItem>
            <Text>
              Attesa, in cui la paziente è in ospedale e aspetta di effettuare
              una delle attività di cura
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Spostamento, ovvero il tempo che il paziente impiega dal domicilio
              a recarsi in ospedale o in farmacia e a tornare a casa
            </Text>
          </ListItem>
        </UnorderedList>
        <Text mb="20px">
          Il percorso del paziente comprende le seguenti attività:
        </Text>
        <Text variant="bodySmallBold">
          {allPatients ? 'Paziente e caregiver EV' : 'Paziente EV'}
        </Text>
        <UnorderedList mb="20px">
          <ListItem>
            <Text>
              Accessi in ospedale per la somministrazione della terapia
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita pre-somministrazione terapia</Text>
              </ListItem>
              <ListItem>
                <Text>- Consegna terapia in reparto</Text>
              </ListItem>
              <ListItem>
                <Text>- Infusione e osservazione</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <Text>
              Accessi in ospedale per le visite di controllo (opzionale)
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita di controllo</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box>
        <Text variant="bodySmallBold">
          {allPatients ? 'Paziente e caregiver SC' : 'Paziente SC'}
        </Text>
        <UnorderedList mb="20px">
          <ListItem>
            <Text>
              Somministrazione a casa della terapia Tempo di somministrazione SC
              al domicilio: 3 min (non modificabile)
            </Text>
          </ListItem>
          <ListItem>
            <Text>Accessi in farmacia per il ritiro della terapia</Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Ritiro della terapia in farmacia</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <Text>
              Accessi in ospedale per le visite di controllo (opzionale)
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita di controllo</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
          {allPatients && (
            <ListItem>
              <Text>
                Il ritiro del farmaco in farmacia è stato considerato solo per
                il paziente e non per il caregiver
              </Text>
            </ListItem>
          )}
        </UnorderedList>
        {allPatients ? (
          <Text>
            Per calcolare i costi social il tempo di pazienti e caregiver
            lavoratori è stato valorizzato per la RAL media italiana:
          </Text>
        ) : (
          <Text>
            Per calcolare i costi social di un paziente lavoratore, il tempo del
            paziente è stato valorizzato per la RAL media italiana:
          </Text>
        )}
        <OrderedList mb="20px">
          <ListItem>
            <Text>
              RAL Paziente: 32.812 €/anno (ISTAT 2021, Condizioni di vita e
              reddito delle famiglie) Considerando 220 giorni lavorativi/anno e
              8 ore lavorative/giorno
            </Text>
          </ListItem>
        </OrderedList>
        <Text>
          Per trasformare il tempo in giornate lavorative perse, è stata fatta
          la seguente assunzione: se il tempo per il singolo accesso in
          ospedale/farmacia dal momento in cui il paziente
          {allPatients && ' caregiver'} esce di casa al momento in cui torna a
          casa:
        </Text>
        <UnorderedList>
          <ListItem>
            <Text>
              {'>'} 4 ore viene considerata 1 giornata lavorativa persa
            </Text>
          </ListItem>
          <ListItem>
            <Text>≤ 4 ore viene considerata ½ giornata lavorativa persa</Text>
          </ListItem>
        </UnorderedList>
      </Box>
    </SimpleGrid>
  );

  return (
    <PopUp
      trigger={<IconSvg icon="info" size="40px" />}
      message={InfusionText}
      placement="bottom-start"
      mx={0}
      my={0}
    />
  );
};

export default InfoPopUp;
