import {useEffect, useState} from 'react';
import {Box, Flex} from '@chakra-ui/react';

import {useInputDataContext} from '@contexts/inputData';
import PatientsGrid from '@molecules/grid/patients/patientsGrid';
import {AllPatientsTable, SinglePatientTable} from '@molecules/table/patients';
import {PatientResultsGraphic} from '@atoms/PatientResultsGraphic';
import {PatientChartProps} from '@atoms/PatientResultsGraphic/interfaces';
import {Text} from '@atoms/Text';
import {useCommonInput} from '@hooks/useCommonInput';
import {useCommonSwitch} from '@hooks/useCommonSwitch';
import {useScenariosInputs} from '@hooks/useScenariosInputs';
import {
  getSinglePatientChartData,
  getAllPatientChartData,
} from '@utils/chart/patients';
import {
  CheckupWorkingTimeLost,
  DosingWorkingTimeLost,
  PatientResultsForAllPatients,
  PatientResultsForSinglePatient,
  TherapyPickupWorkingTimeLost,
} from '@utils/formulas/patient/definitions';
import {
  getCheckupWorkingTimeLost,
  getDosingWorkingTimeLost,
  getPatientsResultsForAllPatients,
  getPatientsResultsForSinglePatient,
  getTherapyPickupWorkingTimeLost,
} from '@utils/formulas/patient/patientResults';
import {round} from '@utils/math';
import {scenariosDataIsNotNull} from '@utils/scenarios';
import {formatNumber} from '@utils/string';
import {fixedInputValues} from '@config';

import InfoPopUp from './infoPopUp';

const PatientsSection = () => {
  //Get data and methods from context
  const {patientData, staffData, commonData, scenariosData} =
    useInputDataContext();

  // Handle scenarios data inputs
  const {scenarios, getScenariosInputProps} = useScenariosInputs({
    initialData: scenariosData.current,
  });

  // Handle common data inputs
  const [allPatients, registerAllPatients] = useCommonSwitch('allPatients', {
    initialValue: commonData.current.allPatients,
  });
  const [checkupIncluded, registerCheckupIncluded] = useCommonSwitch(
    'checkupIncluded',
    {
      initialValue: commonData.current.checkupIncluded,
    },
  );
  const [houseToHospitalTime, registerHouseToHospitalTime] = useCommonInput(
    'houseToHospitalTime',
    {
      initialValue: commonData.current.houseToHospitalTime,
    },
  );
  const [houseToPharmacyTime, registerHouseToPharmacyTime] = useCommonInput(
    'houseToPharmacyTime',
    {
      initialValue: commonData.current.houseToPharmacyTime,
    },
  );
  const [patientsCount, registerPatientCount] = useCommonInput(
    'patientsCount',
    {
      initialValue: commonData.current.patientsCount,
    },
  );
  const [workingPatientsPercentage, registerWorkingPatientsPercentage] =
    useCommonInput('workingPatientsPercentage', {
      initialValue: commonData.current.workingPatientsPercentage,
    });
  const [
    patientsWithCaregiverPercentage,
    registerPatientsWithCaregiverPercentage,
  ] = useCommonInput('patientsWithCaregiverPercentage', {
    initialValue: commonData.current.patientsWithCaregiverPercentage,
  });
  const [workingCaregiversPercentage, registerWorkingCaregiversPercentage] =
    useCommonInput('workingCaregiversPercentage', {
      initialValue: commonData.current.workingCaregiversPercentage,
    });

  // Store results data
  const [dosingTimeResults, setDosingTimeResults] =
    useState<DosingWorkingTimeLost>();
  const [checkupTimeResults, setCheckupTimeResults] =
    useState<CheckupWorkingTimeLost>();
  const [therapyPickupTimeResults, setTherapyPickupTimeResults] =
    useState<TherapyPickupWorkingTimeLost>();
  const [singlePatientResults, setSinglePatientResults] =
    useState<PatientResultsForSinglePatient>();
  const [allPatientResults, setAllPatientResults] =
    useState<PatientResultsForAllPatients>();

  // Store graphic data
  const [singlePatientChartData, setSinglePatientChartData] = useState<
    PatientChartProps['data']
  >([]);
  const [allPatientChartData, setAllPatientChartData] = useState<
    PatientChartProps['data']
  >([]);

  // Calculate and store dosing time results
  useEffect(() => {
    if (houseToHospitalTime !== null) {
      const results = getDosingWorkingTimeLost(
        patientData.current,
        staffData.current,
        fixedInputValues,
        houseToHospitalTime,
      );

      setDosingTimeResults(results);
    } else {
      setDosingTimeResults(undefined);
    }
  }, [houseToHospitalTime, patientData, staffData]);

  // Calculate and store checkup time results
  useEffect(() => {
    if (houseToHospitalTime !== null) {
      const results = getCheckupWorkingTimeLost(
        patientData.current,
        staffData.current,
        fixedInputValues,
        houseToHospitalTime,
      );

      setCheckupTimeResults(results);
    } else {
      setCheckupTimeResults(undefined);
    }
  }, [houseToHospitalTime, patientData, staffData]);

  // Calculate and store therapy pickup time results
  useEffect(() => {
    if (houseToPharmacyTime !== null) {
      const results = getTherapyPickupWorkingTimeLost(
        patientData.current,
        staffData.current,
        fixedInputValues,
        houseToPharmacyTime,
      );

      setTherapyPickupTimeResults(results);
    } else {
      setTherapyPickupTimeResults(undefined);
    }
  }, [houseToPharmacyTime, patientData, staffData]);

  // Calculate results for single patient
  useEffect(() => {
    if (dosingTimeResults && checkupTimeResults && therapyPickupTimeResults) {
      const results = getPatientsResultsForSinglePatient(
        dosingTimeResults,
        checkupTimeResults,
        therapyPickupTimeResults,
        patientData.current,
        fixedInputValues,
        checkupIncluded,
      );

      setSinglePatientResults(results);
    } else {
      setSinglePatientResults(undefined);
    }
  }, [
    checkupIncluded,
    checkupTimeResults,
    dosingTimeResults,
    patientData,
    therapyPickupTimeResults,
  ]);

  // Calculate results for all patients
  useEffect(() => {
    if (
      singlePatientResults &&
      scenariosDataIsNotNull(scenarios) &&
      patientsCount !== null &&
      workingPatientsPercentage !== null &&
      patientsWithCaregiverPercentage !== null &&
      workingCaregiversPercentage !== null &&
      dosingTimeResults &&
      checkupTimeResults
    ) {
      const results = getPatientsResultsForAllPatients(
        singlePatientResults,
        scenarios,
        patientsCount,
        workingPatientsPercentage,
        patientsWithCaregiverPercentage,
        workingCaregiversPercentage,
        dosingTimeResults,
        checkupTimeResults,
        checkupIncluded,
        patientData.current,
        fixedInputValues,
      );

      setAllPatientResults(results);
    } else {
      setAllPatientResults(undefined);
    }
  }, [
    checkupIncluded,
    checkupTimeResults,
    dosingTimeResults,
    patientData,
    patientsCount,
    patientsWithCaregiverPercentage,
    scenarios,
    singlePatientResults,
    workingCaregiversPercentage,
    workingPatientsPercentage,
  ]);

  // Update single patient chart data
  useEffect(() => {
    if (singlePatientResults) {
      setSinglePatientChartData(
        getSinglePatientChartData(singlePatientResults),
      );
    } else {
      setSinglePatientChartData([]);
    }
  }, [singlePatientResults]);

  // Update all patients chart data
  useEffect(() => {
    if (allPatientResults) {
      setAllPatientChartData(getAllPatientChartData(allPatientResults));
    } else {
      setAllPatientChartData([]);
    }
  }, [allPatientResults]);

  return (
    <Flex pl={65} flexDirection="column" gap={2} mt="25px">
      <Flex w={370} gap={3}>
        <InfoPopUp allPatients={allPatients} />
        <Text
          variant="titleLarge"
          color="neutral.200"
          textTransform="uppercase"
          textAlign="center">
          {allPatients
            ? 'Tempo dedicato da pazienti e caregiver lavoratori per anno'
            : 'Tempo dedicato dal paziente in un anno per la gestione della patologia'}
        </Text>
      </Flex>
      {allPatients ? (
        <Box>
          <PatientResultsGraphic
            name={['OGGI', 'DOMANI']}
            data={allPatientChartData}
            delta={allPatientResults?.detailHours.delta.hoursPercent}
          />
          <AllPatientsTable
            totalHoursToday={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.detailHours.today.total, 1),
                  )}h`
                : ''
            }
            totalHoursTomorrow={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.detailHours.tomorrow.total, 1),
                  )}h`
                : ''
            }
            socialCostEv={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.globalCost.today.total),
                  )}€`
                : ''
            }
            socialCostSc={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.globalCost.tomorrow.total),
                  )}€`
                : ''
            }
            lostDaysEv={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.globalDays.today.total),
                  )}`
                : ''
            }
            lostDaysSc={
              allPatientResults
                ? `${formatNumber(
                    round(allPatientResults.globalDays.tomorrow.total),
                  )}`
                : ''
            }
            costDelta={
              allPatientResults
                ? `Δ ${formatNumber(round(allPatientResults.globalCost.delta), {
                    signDisplay: 'exceptZero',
                  })}€`
                : ''
            }
            lostDaysDelta={
              allPatientResults
                ? `Δ ${formatNumber(round(allPatientResults.globalDays.delta), {
                    signDisplay: 'exceptZero',
                  })}`
                : ''
            }
          />
        </Box>
      ) : (
        <Box>
          <PatientResultsGraphic
            name={['EV', 'SC']}
            data={singlePatientChartData}
            delta={singlePatientResults?.delta.hoursPercent}
          />
          <SinglePatientTable
            totalHoursEv={
              singlePatientResults
                ? `${formatNumber(
                    round(singlePatientResults.ev.total.hours, 1),
                  )}h`
                : ''
            }
            totalHoursSc={
              singlePatientResults
                ? `${formatNumber(
                    round(singlePatientResults.sc.total.hours, 1),
                  )}h`
                : ''
            }
            socialCostEv={
              singlePatientResults
                ? `${formatNumber(round(singlePatientResults.ev.total.cost))}€`
                : ''
            }
            socialCostSc={
              singlePatientResults
                ? `${formatNumber(round(singlePatientResults.sc.total.cost))}€`
                : ''
            }
            lostDaysEv={
              singlePatientResults
                ? `${formatNumber(round(singlePatientResults.ev.total.days))}`
                : ''
            }
            lostDaysSc={
              singlePatientResults
                ? `${formatNumber(round(singlePatientResults.sc.total.days))}`
                : ''
            }
            costDelta={
              singlePatientResults
                ? `Δ ${formatNumber(round(singlePatientResults.delta.cost), {
                    signDisplay: 'exceptZero',
                  })}€`
                : ''
            }
            lostDaysDelta={
              singlePatientResults
                ? `Δ ${formatNumber(round(singlePatientResults.delta.days), {
                    signDisplay: 'exceptZero',
                  })}`
                : ''
            }
          />
        </Box>
      )}
      <Box position="absolute" right={0} zIndex={0} height="100%">
        <PatientsGrid
          allPatients={allPatients}
          allPatientsRegister={registerAllPatients()}
          checkUpIncludedRegister={registerCheckupIncluded()}
          houseToHospital={registerHouseToHospitalTime()}
          houseToPharmacy={registerHouseToPharmacyTime()}
          todaySc={getScenariosInputProps('today', 'sc')}
          todayEv={getScenariosInputProps('today', 'ev')}
          tomorrowSc={getScenariosInputProps('tomorrow', 'sc')}
          tomorrowEv={getScenariosInputProps('tomorrow', 'ev')}
          patientsWorkers={registerWorkingPatientsPercentage()}
          patientsCount={registerPatientCount()}
          patientsCaregiver={registerPatientsWithCaregiverPercentage()}
          workersCaregiver={registerWorkingCaregiversPercentage()}
        />
      </Box>
    </Flex>
  );
};

export default PatientsSection;
