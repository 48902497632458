import {Flex, Grid, GridItem} from '@chakra-ui/react';

import {CustomNumberInput} from '@atoms/inputNumber';
import {Switch} from '@atoms/Switch';
import {Text} from '@atoms/Text';

import {IProps} from './interfaces';

const PatientsGrid = ({
  allPatients,
  allPatientsRegister,
  checkUpIncludedRegister,
  houseToHospital,
  houseToPharmacy,
  todaySc,
  todayEv,
  tomorrowSc,
  tomorrowEv,
  patientsWorkers,
  patientsCount,
  patientsCaregiver,
  workersCaregiver,
}: IProps) => {
  return (
    <Flex
      w={265}
      bg="neutral.100"
      justifyContent="flex-start"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="25px 15px"
      gap={30}>
      <Switch
        id="patient"
        leftLabel="Singolo paziente"
        rightLabel="Tutti i pazienti"
        {...allPatientsRegister}
      />
      <Switch
        id="visit"
        leftLabel="Senza visita controllo"
        rightLabel="Con visita controllo"
        {...checkUpIncludedRegister}
      />
      {allPatients ? (
        <Grid
          height="445px"
          templateRows="repeat(9, 1fr)"
          templateColumns="repeat(5, 1fr)"
          alignItems="center"
          gap={2}>
          <GridItem rowSpan={1} colSpan={2} />
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text variant="bodyMedium">SC</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text variant="bodyMedium">EV</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={2} textAlign="right">
            <Text>OGGI</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...todaySc} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...todayEv} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text>%</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={2} textAlign="right">
            <Text>DOMANI</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...tomorrowSc} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...tomorrowEv} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text>%</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={2} textAlign="right">
            <Text>N° pazienti in trattamento</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...patientsCount} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Tempo di spostamento casa - ospedale</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...houseToHospital} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>min</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Tempo di spostamento casa - farmacia</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...houseToPharmacy} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>min</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Pazienti lavoratori</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...patientsWorkers} max={100} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>%</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Pazienti accompagnati in ospedale da caregiver</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...patientsCaregiver} max={100} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>%</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Caregiver lavoratori</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...workersCaregiver} max={100} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>%</Text>
          </GridItem>
        </Grid>
      ) : (
        <Grid
          height="100px"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          alignItems="center"
          gap={2}>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Tempo di spostamento casa - ospedale</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...houseToHospital} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>min</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3} textAlign="right">
            <Text>Tempo di spostamento casa - farmacia</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...houseToPharmacy} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="center">
            <Text>min</Text>
          </GridItem>
        </Grid>
      )}
    </Flex>
  );
};

export default PatientsGrid;
