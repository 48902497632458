import {FilledScenariosData} from '@definitions';

import {percentageToDecimal, decimalToPercentage} from '../../math';

import {InfusionCenterResults} from './definitions';

/**
 * Ore poltrona occupate nello scenario di oggi
 * @param chairOccupationTime
 * @param patientsCount
 * @param dosingCount
 * @param evPercentage
 */
export const getOccupiedChairs = (
  chairOccupationTime: number,
  patientsCount: number,
  dosingCount: number,
  evPercentage: number,
): number => {
  return (
    (chairOccupationTime / 60) *
    patientsCount *
    dosingCount *
    percentageToDecimal(evPercentage)
  );
};

/**
 * Ore poltrona occupate nello scenario di domani
 * @param today
 * @param tomorrow
 */
export const getFreedChairsTomorrow = (
  today: number,
  tomorrow: number,
): number => {
  return today - tomorrow;
};

export const getDeltaPercent = (today: number, tomorrow: number): number => {
  return Math.round(decimalToPercentage((tomorrow - today) / today));
};

export const getAdditionalTherapies = (
  freedChairs: number,
  dosingAvarageDuration: number,
): number => {
  return freedChairs / (dosingAvarageDuration / 60);
};

export const getInfusionCenterResults = (
  chairOccupationTime: number,
  patientsCount: number,
  dosingCount: number,
  scenariosData: FilledScenariosData,
  dosingAvarageTime: number,
): InfusionCenterResults => {
  const todayOccupiedChairs = getOccupiedChairs(
    chairOccupationTime,
    patientsCount,
    dosingCount,
    scenariosData.today.ev,
  );

  const tomorrowOccupiedChairs = getOccupiedChairs(
    chairOccupationTime,
    patientsCount,
    dosingCount,
    scenariosData.tomorrow.ev,
  );

  const tomorrowFreedChairs = getFreedChairsTomorrow(
    todayOccupiedChairs,
    tomorrowOccupiedChairs,
  );

  const deltaPercent = getDeltaPercent(
    todayOccupiedChairs,
    tomorrowOccupiedChairs,
  );

  const tomorrowAvailableTherapies = getAdditionalTherapies(
    tomorrowFreedChairs,
    dosingAvarageTime,
  );

  return {
    todayOccupiedChairs,
    tomorrowOccupiedChairs,
    tomorrowFreedChairs,
    deltaPercent,
    tomorrowAvailableTherapies,
  };
};
