import React, {useMemo} from 'react';
import {
  Box,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
} from '@chakra-ui/react';

import {Text} from '@atoms/Text';

import {ValidationModalProps} from './definitions';

const ValidationModal: React.FC<ValidationModalProps> = ({
  errors,
  isOpen,
  onClose,
}) => {
  const groupedErrors = useMemo<Record<string, string>>(() => {
    const keys = Object.keys(
      errors,
    ) as (keyof ValidationModalProps['errors'])[];

    return keys.reduce<Record<string, string>>((result, value) => {
      const evError = errors[value]?.ev;
      const scError = errors[value]?.sc;

      // Se il campo "ev" ha un errore
      // e il "type" dell'errore non è già presente nell'oggetto risultante
      if (
        evError &&
        evError.type &&
        evError.message &&
        result[evError.type] === undefined
      ) {
        result[evError.type] = evError.message;
      }

      // Se il campo "sc" ha un errore
      // e il "type" dell'errore non è già presente nell'oggetto risultante
      if (
        scError &&
        scError.type &&
        scError.message &&
        result[scError.type] === undefined
      ) {
        result[scError.type] = scError.message;
      }

      return result;
    }, {});
  }, [errors]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="neutral.100">
        <ModalHeader>
          <Text
            variant="bodyMedium"
            color="primary.main"
            textAlign="center"
            textTransform="uppercase">
            Attenzione!
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="20px" justifyContent="center" display="flex">
          {/*Object.keys(groupedErrors).length > 0 && (
            <UnorderedList>
              {Object.keys(groupedErrors).map(errorType => (
                <ListItem key={errorType}>
                  <Text as="p" variant="titleSmall">
                    {groupedErrors[errorType]}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
              )*/}
          <Box w={310} textAlign="left">
            <Text>
              Per proseguire alla visualizzazione dei risultati le seguenti
              condizioni devono essere rispettate:
            </Text>
            <UnorderedList mb="20px">
              <ListItem>
                <Text>
                  Il tempo di occupazione poltrona del paziente non può essere
                  inferiore a quello dedicato dal personale ospedaliero
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  Il tempo della visita di controllo per il personale
                  ospedaliero non può superare il tempo totale di permanenza del
                  paziente in ospedale
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  La somma dei tempi delle singole attività non può superare il
                  tempo totale di permanenza del paziente in ospedale
                </Text>
              </ListItem>
            </UnorderedList>
            <Text>
              Controllare le celle evidenziate in verdeacqua e modificare i
              valori per garantire la coerenza dei dati prima di proseguire
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ValidationModal;
