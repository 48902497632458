import {useEffect} from 'react';
import {useAuth} from 'react-oidc-context';
import {UserManagement} from '@piwikpro/react-piwik-pro';

import {Login} from '@organisms/login';
import {Main} from '@organisms/main';

const App = () => {
  const auth = useAuth();

  /*  useEffect(() => {
    return auth.events.addUserLoaded(user => {
      console.log('User loaded');
    });
  }, [auth]);

  useEffect(() => {
    return auth.events.addUserUnloaded(() => {
      console.log('User unloaded');
    });
  }, [auth]);

  useEffect(() => {
    return auth.events.addUserSignedIn(() => {
      console.log('User signed in');
    });
  }, [auth]);

  useEffect(() => {
    return auth.events.addUserSignedOut(() => {
      console.log('User signed out');
    });
  }, [auth]);*/

  // - Add user id to tracking session when user signs in
  // - Remve user id from tracking session when user signs out
  useEffect(() => {
    if (auth?.user?.profile?.sub) {
      UserManagement.setUserId(String(auth?.user?.profile?.sub));
    } else {
      UserManagement.resetUserId();
    }
  }, [auth?.user?.profile?.sub]);

  // Render content if SSO is disabled or if user is authenticated
  if (process.env.REACT_APP_SSO_ENABLED === 'false' || auth.isAuthenticated) {
    return <Main />;
  }

  return <Login />;
};

export default App;
