import {useEffect, useState} from 'react';
import {Flex, Box, Image, UnorderedList, ListItem} from '@chakra-ui/react';

import {useInputDataContext} from '@contexts/inputData';
import {InfusionCenterGrid} from '@molecules/grid/InfusionCenter';
import {IconSvg} from '@atoms/IconSvg';
import {InfusionCenterGraphic} from '@atoms/InfusionCenterGraphic';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';
import {useCommonInput} from '@hooks/useCommonInput';
import {useScenariosInputs} from '@hooks/useScenariosInputs';
import {InfusionCenterResults} from '@utils/formulas/infusionCenter/definitions';
import {getInfusionCenterResults} from '@utils/formulas/infusionCenter/infusionCenterResults';
import {round} from '@utils/math';
import {scenariosDataIsNotNull} from '@utils/scenarios';
import {formatNumber} from '@utils/string';
import graffa from '@theme/infusion_graffa.png';

const InfusionCenterSection = () => {
  // Get data and methods from context
  const {patientData, scenariosData, commonData} = useInputDataContext();

  // Store results data
  const [results, setResults] = useState<InfusionCenterResults>();

  // Handle scenarios data inputs
  const {scenarios, getScenariosInputProps} = useScenariosInputs({
    initialData: scenariosData.current,
  });

  // Handle common data inputs
  const [patientsCount, registerPatientCount] = useCommonInput(
    'patientsCount',
    {
      initialValue: commonData.current.patientsCount,
    },
  );
  const [dosingAvarageTime, registerDosingAvarageTime] = useCommonInput(
    'dosingAvarageTime',
    {
      initialValue: commonData.current.dosingAvarageTime,
    },
  );

  // Update results
  useEffect(() => {
    if (
      patientsCount !== null &&
      dosingAvarageTime !== null &&
      scenariosDataIsNotNull(scenarios)
    ) {
      const results = getInfusionCenterResults(
        patientData?.current.chairOccupationTime.ev!,
        patientsCount,
        patientData?.current.dosingCount.ev!,
        scenarios,
        dosingAvarageTime,
      );

      setResults(results);
    } else {
      setResults(undefined);
    }
  }, [dosingAvarageTime, patientData, patientsCount, scenarios]);

  const titlePopUp = (
    <Box w={260}>
      <Text>Il grafico mostra:</Text>
      <UnorderedList>
        <ListItem>
          <Text>
            le ore poltrona occupate dai pazienti in terapia EV considerando la
            percentuale EV negli scenari «Oggi» e «Domani»
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            le ore poltrona liberabili annualmente passando dallo scenario
            «Oggi» allo scenario «Domani»
          </Text>
        </ListItem>
      </UnorderedList>
    </Box>
  );

  const therapyPopUp = (
    <Box w={260}>
      <Text>
        Numero di terapie generiche aggiuntive erogabili annualmente all’interno
        del centro infusionale passando dallo scenario «Oggi» allo scenario
        «Domani»
      </Text>
    </Box>
  );

  return (
    <Flex pl={65} mt="25px">
      <Box position="relative" w="100%">
        <Flex w={300} gap={3}>
          <PopUp
            trigger={<IconSvg icon="info" />}
            message={titlePopUp}
            mx={0}
            placement="bottom-start"
          />
          <Text
            variant="titleLarge"
            color="neutral.200"
            textTransform="uppercase"
            textAlign="center">
            Ore Poltrona liberabili annualmente
          </Text>
        </Flex>
        <Box position="relative" w={610}>
          <InfusionCenterGraphic
            todayOccupiedChairs={results?.todayOccupiedChairs}
            tomorrowFreedChairs={results?.tomorrowFreedChairs}
            tomorrowOccupiedChairs={results?.tomorrowOccupiedChairs}
            delta={results?.deltaPercent}
          />
          {results && (
            <Flex position="absolute" right={0} bottom={55} w={225}>
              <Image src={graffa} height={140} pr={4} />
              <Flex flexDirection="column" justifyContent="space-between">
                <Box>
                  <Text variant="titleLarge" color="primary.main">
                    {formatNumber(round(results.tomorrowFreedChairs), {
                      signDisplay: 'exceptZero',
                    })}
                  </Text>
                  <Text variant="bodyMedium">Ore poltrona liberate</Text>
                </Box>
                <Flex alignItems="center">
                  <Box>
                    <Text variant="titleLarge" color="primary.main">
                      {formatNumber(round(results.tomorrowAvailableTherapies), {
                        signDisplay: 'exceptZero',
                      })}
                    </Text>
                    <Text variant="bodyMedium">
                      Terapie aggiuntive erogabili
                    </Text>
                  </Box>
                  <Flex alignSelf="end">
                    <PopUp
                      trigger={<IconSvg icon="info" />}
                      message={therapyPopUp}
                      placement="left"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box position="absolute" right={0} top={0} zIndex={0}>
          <InfusionCenterGrid
            todaySc={getScenariosInputProps('today', 'sc')}
            todayEv={getScenariosInputProps('today', 'ev')}
            tomorrowSc={getScenariosInputProps('tomorrow', 'sc')}
            tomorrowEv={getScenariosInputProps('tomorrow', 'ev')}
            patients={registerPatientCount()}
            averageTime={registerDosingAvarageTime()}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default InfusionCenterSection;
