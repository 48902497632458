import React from 'react';
import {Log as OidcLog} from 'oidc-client-ts';
import {isMobile} from 'react-device-detect';
import ReactDOM from 'react-dom/client';
import {AuthProvider, AuthProviderProps} from 'react-oidc-context';
import {Flex, Box} from '@chakra-ui/react';
import {ChakraProvider} from '@chakra-ui/react';
import PiwikPro, {CustomEvent} from '@piwikpro/react-piwik-pro';

import {InputDataProvider} from '@contexts/inputData';
import {SectionsProvider} from '@contexts/sections';
import {App} from '@organisms/app';
import {Fonts} from '@atoms/Fonts';
import {theme} from '@theme/theme';

import reportWebVitals from './reportWebVitals';

// Set auth manager logger if in dev mode
if (process.env.NODE_ENV === 'development') {
  OidcLog.setLogger(console);
}

// Create auth manager config object
const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_SSO_AUTHORITY!,
  client_id: process.env.REACT_APP_SSO_CLIENT_ID!,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI!,
  scope: process.env.REACT_APP_SSO_SCOPES,
  onSigninCallback: user => {
    window.history.replaceState({}, document.title, window.location.origin);
    const userEmail = user?.profile?.email;
    if (userEmail) {
      CustomEvent.trackEvent('Autenticazione', 'Sign In', userEmail);
    }
  },
};

// Initialize Piwik tracking
PiwikPro.initialize(
  process.env.REACT_APP_PIWIK_APP_ID!,
  process.env.REACT_APP_PIWIK_ACCOUNT_URL!,
  'akwhzn5S',
);

// Create application root
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Fonts />
      <AuthProvider {...oidcConfig}>
        <InputDataProvider>
          <SectionsProvider>
            {isMobile ? (
              <App />
            ) : (
              <Flex justifyContent="center" alignItems="center" h="100%">
                <Box
                  w="1024px"
                  h="768px"
                  borderWidth={1}
                  borderColor="common.black">
                  <App />
                </Box>
              </Flex>
            )}
          </SectionsProvider>
        </InputDataProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
