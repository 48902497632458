import {IPatientsResults} from '@atoms/HospitalResultsGraphic/interfaces';
import {
  HospitalResultsForAllPatients,
  HospitalResultsForSinglePatient,
} from '@utils/formulas/hospital/definitions';
import {round} from '@utils/math';
import {theme} from '@theme/theme';

export const getSinglePatientChartData = (
  results: HospitalResultsForSinglePatient,
): IPatientsResults[] => {
  return [
    {
      name: 'Infermiere',
      color: theme.colors.chars.Nurse,
      values: [results.ev.nurse.hoursPerYear, results.sc.nurse.hoursPerYear],
      percents: [
        round(results.ev.nurse.hoursPercent),
        round(results.sc.nurse.hoursPercent),
      ],
    },
    {
      name: 'Medico',
      color: theme.colors.chars.Doctor,
      values: [results.ev.doctor.hoursPerYear, results.sc.doctor.hoursPerYear],
      percents: [
        round(results.ev.doctor.hoursPercent),
        round(results.sc.doctor.hoursPercent),
      ],
    },
    {
      name: 'Farmacista',
      color: theme.colors.chars.Pharmacist,
      values: [
        results.ev.pharmacist.hoursPerYear,
        results.sc.pharmacist.hoursPerYear,
      ],
      percents: [
        round(results.ev.pharmacist.hoursPercent),
        round(results.sc.pharmacist.hoursPercent),
      ],
    },
    {
      name: 'Amministrativo',
      color: theme.colors.chars.Administrator,
      values: [
        results.ev.administrative.hoursPerYear,
        results.sc.administrative.hoursPerYear,
      ],
      percents: [
        round(results.ev.administrative.hoursPercent),
        round(results.sc.administrative.hoursPercent),
      ],
    },
  ];
};

export const getAllPatientChartData = (
  results: HospitalResultsForAllPatients,
): IPatientsResults[] => {
  return [
    {
      name: 'EV',
      color: theme.colors.chars.Ev,
      values: [results.today.ev.hours, results.tomorrow.ev.hours],
      percents: [
        Math.round(results.today.ev.hoursPercent),
        Math.round(results.tomorrow.ev.hoursPercent),
      ],
    },
    {
      name: 'SC',
      color: theme.colors.chars.Sc,
      values: [results.today.sc.hours, results.tomorrow.sc.hours],
      percents: [
        Math.round(results.today.sc.hoursPercent),
        Math.round(results.tomorrow.sc.hoursPercent),
      ],
    },
  ];
};
