export const symbols = [
  'path://M2.986,0c-5.727,28.984 -3.876,52.674 11.37,82.431c27.104,52.901 56.427,104.652 83.961,157.338c28.578,54.683 28.672,40.495 -0.267,95.444c-28.203,53.551 -56.994,106.79 -85.485,160.191c-13.07,24.496 -13.215,50.781 -10.716,79.263l193.811,-0l-0,-574.667l-192.674,0Z',
  'path://M192.674,0c5.727,28.984 3.876,52.674 -11.37,82.431c-27.104,52.901 -56.427,104.652 -83.961,157.338c-28.579,54.683 -28.672,40.495 0.267,95.444c28.203,53.551 56.994,106.79 85.485,160.191c13.07,24.496 13.215,50.781 10.716,79.263l-193.811,-0l0,-574.667l192.674,0Z',
];

export const labelSetting = {
  show: true,
  position: 'top',
  color: 'inherit',
  fontWeight: 'bold',
  fontSize: 15,
  fontFamily: 'Arial',
};

export const axisLabel = {
  rich: {
    xToday: {
      ...labelSetting,
      color: '#000',
      //padding: [0, 0, 0, 15],
    },
    xTomorrow: {
      ...labelSetting,
      color: '#000',
      padding: [0, 145, 0, 0],
    },
  },
};
