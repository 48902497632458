import {useEffect, useState} from 'react';
import {Box, Flex} from '@chakra-ui/react';

import {useInputDataContext} from '@contexts/inputData';
import HospitalGrid from '@molecules/grid/hospital/hospitalGrid';
import {HospitalTable} from '@molecules/table/hospital';
import {HospitalResultsGraphic} from '@atoms/HospitalResultsGraphic';
import {HospitalChartProps} from '@atoms/HospitalResultsGraphic/interfaces';
import {Text} from '@atoms/Text';
import {useCommonInput} from '@hooks/useCommonInput';
import {useCommonSwitch} from '@hooks/useCommonSwitch';
import {useScenariosInputs} from '@hooks/useScenariosInputs';
import {
  getSinglePatientChartData,
  getAllPatientChartData,
} from '@utils/chart/hospital';
import {
  HospitalResultsForSinglePatient,
  HospitalResultsForAllPatients,
} from '@utils/formulas/hospital/definitions';
import {
  getHospitalResultsForSinglePatient,
  getHospitalResultsForAllPatients,
} from '@utils/formulas/hospital/hospitalResults';
import {round} from '@utils/math';
import {scenariosDataIsNotNull} from '@utils/scenarios';
import {formatNumber} from '@utils/string';
import {fixedInputValues} from '@config';

import InfoPopUp from './infoPopUp';

const HospitalSection = () => {
  //Get data and methods from context
  const {patientData, staffData, commonData, scenariosData} =
    useInputDataContext();

  // Handle scenarios data inputs
  const {scenarios, getScenariosInputProps} = useScenariosInputs({
    initialData: scenariosData.current,
  });

  // Handle common data inputs
  const [allPatients, registerAllPatients] = useCommonSwitch('allPatients', {
    initialValue: commonData.current.allPatients,
  });
  const [checkupIncluded, registerCheckupIncluded] = useCommonSwitch(
    'checkupIncluded',
    {
      initialValue: commonData.current.checkupIncluded,
    },
  );

  const [patientsCount, registerPatientCount] = useCommonInput(
    'patientsCount',
    {
      initialValue: commonData.current.patientsCount,
    },
  );

  // Store results data
  const [singlePatientResults, setSinglePatientResults] =
    useState<HospitalResultsForSinglePatient>();
  const [allPatientResults, setAllPatientResults] =
    useState<HospitalResultsForAllPatients>();

  // Store graphic data
  const [singlePatientChartData, setSinglePatientChartData] = useState<
    HospitalChartProps['data']
  >([]);
  const [allPatientChartData, setAllPatientChartData] = useState<
    HospitalChartProps['data']
  >([]);

  // Update single patient results
  useEffect(() => {
    const results = getHospitalResultsForSinglePatient(
      staffData.current,
      patientData.current,
      fixedInputValues.staffWage,
      checkupIncluded,
    );

    setSinglePatientResults(results);
  }, [checkupIncluded, patientData, staffData]);

  // Update all patients results
  useEffect(() => {
    if (
      allPatients &&
      singlePatientResults &&
      patientsCount !== null &&
      scenariosDataIsNotNull(scenarios)
    ) {
      const results = getHospitalResultsForAllPatients(
        singlePatientResults,
        scenarios,
        patientsCount,
      );

      setAllPatientResults(results);
    } else {
      setAllPatientResults(undefined);
    }
  }, [allPatients, patientsCount, scenarios, singlePatientResults]);

  // Update single patient chart data
  useEffect(() => {
    if (singlePatientResults) {
      setSinglePatientChartData(
        getSinglePatientChartData(singlePatientResults),
      );
    } else {
      setSinglePatientChartData([]);
    }
  }, [singlePatientResults]);

  // Update all patients chart data
  useEffect(() => {
    if (allPatientResults) {
      setAllPatientChartData(getAllPatientChartData(allPatientResults));
    } else {
      setAllPatientChartData([]);
    }
  }, [allPatientResults]);

  return (
    <Flex pl={65} flexDirection="column" gap={2} mt="25px">
      <Flex w={370} gap={3}>
        <InfoPopUp allPatients={allPatients} />
        <Text
          variant="titleLarge"
          color="neutral.200"
          textTransform="uppercase"
          textAlign="center">
          {allPatients
            ? 'Tempo dedicato dal personale ospedaliero in un anno per la gestione di tutti i pazienti'
            : 'Tempo dedicato dal personale ospedaliero in un anno per la gestione di un paziente'}
        </Text>
      </Flex>
      <Box>
        {allPatients ? (
          <HospitalResultsGraphic
            name={['OGGI', 'DOMANI']}
            data={allPatientChartData}
            delta={allPatientResults?.delta.hoursPercent}
          />
        ) : (
          <HospitalResultsGraphic
            name={['EV', 'SC']}
            data={singlePatientChartData}
            delta={singlePatientResults?.delta.hoursPercent}
          />
        )}
        <HospitalTable
          allPatients={allPatients}
          allDelta={
            allPatientResults
              ? `Δ ${formatNumber(round(allPatientResults.delta.cost), {
                  signDisplay: 'exceptZero',
                })}€`
              : ''
          }
          singleDelta={
            singlePatientResults
              ? `Δ ${formatNumber(round(singlePatientResults.delta.cost), {
                  signDisplay: 'exceptZero',
                })}€`
              : ''
          }
          hoursEv={
            singlePatientResults
              ? `${formatNumber(
                  round(singlePatientResults.ev.total.hoursPerYear, 1),
                )}h`
              : ''
          }
          hoursSc={
            singlePatientResults
              ? `${formatNumber(
                  round(singlePatientResults.sc.total.hoursPerYear, 1),
                )}h`
              : ''
          }
          costEv={
            singlePatientResults
              ? `${formatNumber(
                  round(singlePatientResults.ev.total.costPerYear),
                )}€`
              : ''
          }
          costSc={
            singlePatientResults
              ? `${formatNumber(
                  round(singlePatientResults.sc.total.costPerYear),
                )}€`
              : ''
          }
          hoursToday={
            allPatientResults
              ? `${formatNumber(
                  round(allPatientResults.today.total.hours, 1),
                )}h`
              : ''
          }
          hoursTomorrow={
            allPatientResults
              ? `${formatNumber(
                  round(allPatientResults.tomorrow.total.hours, 1),
                )}h`
              : ''
          }
          costToday={
            allPatientResults
              ? `${formatNumber(round(allPatientResults.today.total.cost))}€`
              : ''
          }
          costTomorrow={
            allPatientResults
              ? `${formatNumber(round(allPatientResults.tomorrow.total.cost))}€`
              : ''
          }
        />
      </Box>
      <Box position="absolute" right={0} zIndex={0} height="100%">
        <HospitalGrid
          allPatients={allPatients}
          allPatientsRegister={registerAllPatients()}
          checkUpIncludedRegister={registerCheckupIncluded()}
          todaySc={getScenariosInputProps('today', 'sc')}
          todayEv={getScenariosInputProps('today', 'ev')}
          tomorrowSc={getScenariosInputProps('tomorrow', 'sc')}
          tomorrowEv={getScenariosInputProps('tomorrow', 'ev')}
          patients={registerPatientCount()}
        />
      </Box>
    </Flex>
  );
};

export default HospitalSection;
