import ReactEcharts from 'echarts-for-react';
import {Box} from '@chakra-ui/react';

import {Delta} from '@atoms/Delta';
import {round} from '@utils/math';
import {formatNumber} from '@utils/string';
import {theme} from '@theme/theme';

import {symbols, labelSetting, axisLabel} from './config';
import {InfusionCenterChartProps} from './interfaces';

const InfusionCenterGraphic = ({
  todayOccupiedChairs,
  tomorrowOccupiedChairs,
  delta,
}: InfusionCenterChartProps) => {
  let bodyMax = 0;

  if (
    todayOccupiedChairs !== undefined &&
    tomorrowOccupiedChairs !== undefined
  ) {
    bodyMax =
      todayOccupiedChairs < tomorrowOccupiedChairs
        ? tomorrowOccupiedChairs
        : todayOccupiedChairs;
  }

  const option = {
    tooltip: {},
    legend: {
      icon: 'roundRect',
      selectedMode: false,
      z: 100,
      orient: 'vertical',
      padding: 0,
      right: 0,
      top: 100,
    },
    barWidth: '90px',
    xAxis: {
      data: ['OGGI', 'DOMANI'],
      axisTick: {show: false},
      axisLine: {show: true},
      axisLabel: {
        ...axisLabel,
        formatter: (value: string, index: number) =>
          index === 0 ? `{xToday|${value}}` : `{xTomorrow|${value}}`,
      },
    },
    yAxis: {
      type: 'value',
      name: 'h/anno',
      nameLocation: 'start',
      nameGap: -10,
      nameTextStyle: {
        align: 'right',
      },
      min: 0,
      max: bodyMax,
      splitLine: {show: false},
      axisLine: {show: true},
      axisTick: {show: true},
      axisLabel: {
        show: true,
        formatter: (value: number) => (value === 0 ? null : Math.round(value)),
      },
    },
    grid: {
      bottom: 25,
    },
    series: [
      {
        name: 'Ore occupate',
        type: 'pictorialBar',
        legendHoverLink: false,
        symbolClip: true,
        silent: true,
        symbolBoundingData: bodyMax,
        itemStyle: {
          color: theme.colors.chars.OccupiedChairs,
        },
        data: [
          {
            value:
              todayOccupiedChairs !== undefined
                ? Math.round(todayOccupiedChairs)
                : 0,
            symbol: symbols[0],
            symbolOffset: [5, 0],
            label: {
              ...labelSetting,
              formatter: (data: {value: number}) => {
                return data.value === 0 ? '' : `${data.value} h`;
              },
              offset: [10, 0],
            },
            z: 10,
          },
          {
            value:
              tomorrowOccupiedChairs !== undefined
                ? Math.round(tomorrowOccupiedChairs)
                : 0,
            symbol: symbols[1],
            symbolOffset: [-70, 0],
            z: 10,
          },
        ],
      },
      {
        name: 'Ore poltrona\nliberate',
        type: 'pictorialBar',
        legendHoverLink: false,
        silent: true,
        symbolClip: true,
        symbolBoundingData: bodyMax,
        itemStyle: {
          color: theme.colors.chars.FreeChairs,
        },
        data: [
          {
            value: 0,
            symbol: symbols[0],
            symbolOffset: [5, 0],
            z: 10,
          },
          {
            value: 0,
            symbol: symbols[1],
            symbolOffset: [-70, 0],
            itemStyle: {
              color: theme.colors.chars.OccupiedChairs,
            },
            label: {
              ...labelSetting,
              formatter:
                tomorrowOccupiedChairs !== undefined
                  ? `${Math.round(tomorrowOccupiedChairs)} h`
                  : '',
              offset: [-70, 0],
            },
            z: 10,
          },
        ],
      },
      {
        type: 'pictorialBar',
        animation: false,
        legendHoverLink: false,
        silent: true,
        symbolBoundingData: bodyMax,
        animationDuration: 0,
        itemStyle: {
          color: theme.colors.chars.FreeChairs,
        },
        data: [
          {
            value: !todayOccupiedChairs ? 0 : 1,
            symbol: symbols[0],
            symbolOffset: [5, 0],
            itemStyle: {
              color: theme.colors.neutral[50],
            },
          },
          {
            value: !todayOccupiedChairs ? 0 : 1,
            symbol: symbols[1],
            symbolOffset: [-70, 0],
          },
        ],
      },
    ],
  };

  return (
    <Box
      width="-webkit-fit-content"
      height="-webkit-fit-content"
      position="relative">
      <ReactEcharts
        option={option}
        opts={{renderer: 'svg'}}
        style={{width: '425px', height: '530px'}}
      />
      {delta && delta !== Infinity && (
        <Delta
          delta={`${formatNumber(round(delta), {
            signDisplay: 'exceptZero',
          })}%`}
        />
      )}
    </Box>
  );
};

export default InfusionCenterGraphic;
