import {StylesObject} from '@definitions';
import bg from '@theme/GSK-sfondo.jpg';

export const styles: StylesObject = {
  container: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'common.white',
    padding: '10px 20px',
    cursor: 'pointer',
  },
};
