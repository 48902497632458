import React from 'react';
import {Box, ListItem, UnorderedList} from '@chakra-ui/react';

import {Section} from '@contexts/sections/definitions';
import {useSectionsContext} from '@contexts/sections/sections';
import {IconSvg} from '@atoms/IconSvg';
import {Logo} from '@atoms/Logo';
import {MenuIcons} from '@atoms/MenuIcons';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';

import {styles} from './styles';

const Menu = () => {
  const {activeSection, goToSection} = useSectionsContext();

  const List = [
    {
      label: 'INPUT',
      onClick: () => goToSection(Section.INPUT_DATA),
      section: Section.INPUT_DATA,
    },
    {
      label: 'RISULTATI',
      onClick: () => goToSection(Section.INFUSION_CENTER_RESULTS),
      section: Section.INFUSION_CENTER_RESULTS,
    },
    {
      label: 'Centro Infusionale',
      onClick: () => goToSection(Section.INFUSION_CENTER_RESULTS),
      section: Section.INFUSION_CENTER_RESULTS,
    },
    {
      label: 'Ospedale',
      onClick: () => goToSection(Section.HOSPITAL_RESULTS),
      section: Section.HOSPITAL_RESULTS,
    },
    {
      label: 'Paziente',
      onClick: () => goToSection(Section.PATIENT_RESULTS),
      section: Section.PATIENT_RESULTS,
    },
  ];

  const getStyle = (label: string, section: Section) => {
    if (label === 'RISULTATI' && activeSection !== Section.INPUT_DATA) {
      return styles.listItem__active;
    } else if (activeSection === section && section !== Section.INPUT_DATA) {
      return styles.subItem__active;
    } else if (label === 'RISULTATI') {
      return styles.listItem;
    } else if (label === 'INPUT' && activeSection === Section.INPUT_DATA) {
      return styles.listItem__active;
    } else {
      return styles.listItem;
    }
  };

  const logoMessage = (
    <Box w={310}>
      <Text variant="bodySmallBold">
        Il tool{' '}
        <Text as="span" variant="bodySmallBold" color="primary.main">
          LESTApp
        </Text>{' '}
        simula l’impatto economico-organizzativo dell’utilizzo di diverse vie di
        somministrazione della terapia (SC e EV).
      </Text>
      <Text
        variant="bodySmallBold"
        textTransform="uppercase"
        color="primary.main"
        mt="15px">
        Sezioni
      </Text>
      <UnorderedList>
        <ListItem>
          <Text>
            <Text as="span" variant="bodySmallBold">
              INPUT
            </Text>
            : vengono richiesti all’utente i dati chiave per alimentare la
            simulazione
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <Text as="span" variant="bodySmallBold">
              RISULTATI
            </Text>
            : viene visualizzato l’impatto economico-organizzativo delle diverse
            formulazioni dal punto di vista del:
          </Text>
        </ListItem>
        <UnorderedList>
          <ListItem>
            <Text>
              <Text as="span" variant="bodySmallBold">
                Centro infusionale
              </Text>
              : tempo poltrona liberato e terapie aggiuntive erogabili
              all’interno del centro infusionale
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <Text as="span" variant="bodySmallBold">
                Ospedale
              </Text>
              : tempi e costi del personale ospedaliero
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <Text as="span" variant="bodySmallBold">
                Paziente
              </Text>
              : tempi e costi di paziente e caregiver
            </Text>
          </ListItem>
        </UnorderedList>
      </UnorderedList>
    </Box>
  );

  return (
    <Box sx={styles.container}>
      <PopUp
        trigger={<Logo variant="logo" />}
        message={logoMessage}
        placement="right"
      />
      <UnorderedList width="100%" margin={0}>
        {List.map(({label, onClick, section}) => (
          <ListItem
            key={label}
            onClick={onClick}
            cursor="pointer"
            listStyleType="none"
            sx={getStyle(label, section)}>
            <Text variant="titleSmall">{label}</Text>
            {section === activeSection && label !== 'RISULTATI' && (
              <IconSvg icon="graffa" style={{width: 'auto'}} />
            )}
          </ListItem>
        ))}
      </UnorderedList>
      <MenuIcons />
      <Logo variant="gsk_signal" />
    </Box>
  );
};

export default React.memo(Menu);
