import {Flex} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {Text} from '@atoms/Text';

import {IProps} from './interfaces';

const Delta = ({
  delta,
  top = '50%',
  right = 0,
  bottom,
  left,
  alignItems,
}: IProps) => {
  return (
    <Flex
      position="absolute"
      top={top}
      right={right}
      bottom={bottom}
      alignItems={alignItems}
      left={left}
      zIndex={1}>
      <IconSvg
        icon="graffa_orange"
        size={45}
        style={{width: 'auto', position: 'absolute', left: '-14px'}}
      />
      <Flex
        width={90}
        height="45px"
        bg="primary.main"
        color="white"
        alignItems="center"
        justifyContent="center"
        borderRadius={4}>
        <Text variant="titleLarge" textAlign="center">
          {delta}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Delta;
