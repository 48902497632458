import {Box} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {IconNames} from '@atoms/IconSvg/interfaces';
import {PopUp} from '@atoms/PopUp';

import {usePopUpMessages} from './messages';
import {styles} from './styles';

const MenuIcons = () => {
  const {hypothesis, sources, acronyms} = usePopUpMessages();

  const icons = [
    {
      icon: 'hypothesis',
      message: hypothesis,
    },
    {
      icon: 'sources',
      message: sources,
    },
    {
      icon: 'acronyms',
      message: acronyms,
    },
  ];
  return (
    <Box sx={styles.container}>
      {icons.map(({icon, message}) => (
        <PopUp
          key={icon}
          trigger={<IconSvg icon={icon as IconNames} />}
          message={message}
          mx={0}
          placement="top-start"
        />
      ))}
    </Box>
  );
};

export default MenuIcons;
