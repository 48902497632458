import React from 'react';
import {Box, Flex} from '@chakra-ui/react';

import {useSectionsContext} from '@contexts/sections';
import {IconSvg} from '@atoms/IconSvg';
import {Text} from '@atoms/Text';

import {IProps} from './interfaces';

const Navigation = ({title, subtitle}: IProps) => {
  const {goToNext, goToPrev, hasPrevSection, hasNextSection} =
    useSectionsContext();

  return (
    <Flex justifyContent="space-between" alignItems="center" px="10px">
      <Box>
        <Text
          variant="headlineSmall"
          color="neutral.200"
          textTransform="uppercase">
          {subtitle}
        </Text>
        <Text variant="headlineLarge" color="primary.main">
          {title}
        </Text>
      </Box>
      <Flex gap={5}>
        {hasPrevSection && (
          <Box sx={{transform: 'rotateZ(-180deg)', cursor: 'pointer'}}>
            <IconSvg icon="next_arrow" onClick={goToPrev} />
          </Box>
        )}
        {hasNextSection && (
          <Box sx={{cursor: 'pointer'}}>
            <IconSvg icon="next_arrow" onClick={goToNext} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default React.memo(Navigation);
