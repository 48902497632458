import {Box, UnorderedList, ListItem} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';

import {IPopUp} from './interfaces';

const ErrorPopUp = ({placement = 'top'}: IPopUp) => {
  const ErrorText = (
    <Box w={310} textAlign="left">
      <Text
        variant="bodySmallBold"
        textTransform="uppercase"
        color="primary.main"
        textAlign="center"
        mb="15px">
        Attenzione!
      </Text>
      <Text>
        Per proseguire alla visualizzazione dei risultati le seguenti condizioni
        devono essere rispettate:
      </Text>
      <UnorderedList mb="20px">
        <ListItem>
          <Text>
            Il tempo di occupazione poltrona del paziente non può essere
            inferiore a quello dedicato dal personale ospedaliero
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            Il tempo della visita di controllo per il personale ospedaliero non
            può superare il tempo totale di permanenza del paziente in ospedale
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            La somma dei tempi delle singole attività non può superare il tempo
            totale di permanenza del paziente in ospedale
          </Text>
        </ListItem>
      </UnorderedList>
      <Text>
        Controllare le celle evidenziate in verdeacqua e modificare i valori per
        garantire la coerenza dei dati prima di proseguire
      </Text>
    </Box>
  );

  return (
    <PopUp
      trigger={<IconSvg icon="attention" />}
      message={ErrorText}
      placement={placement}
    />
  );
};

export default ErrorPopUp;
