import {StylesObject} from '@definitions';
import patient from '@theme/assets/icons/pazienti.png';
import visit from '@theme/assets/icons/visita.png';
import {theme} from '@theme/theme';

export const styles: StylesObject = {
  switchContainer: {
    '--switch-track-width': '80px',
    '--switch-track-height': '40px',
    margin: '0 5px',
    '.chakra-switch__track': {
      backgroundColor: theme.colors.neutral[200],
    },
    '& #patient + .chakra-switch__track': {
      '.chakra-switch__thumb': {
        content: `url(${patient})`,
      },
    },
    '& #visit + .chakra-switch__track': {
      '.chakra-switch__thumb': {
        content: `url(${visit})`,
      },
    },
  },
  label: {
    color: theme.colors.neutral[200],
    textTransform: 'uppercase',
    margin: 0,
  },
  label__active: {
    color: theme.colors.primary.main,
    textTransform: 'uppercase',
    margin: 0,
  },
};
