import {
  Box,
  ListItem,
  OrderedList,
  SimpleGrid,
  UnorderedList,
} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';

import {IPopUp} from './interfaces';

const InfoPopUp = ({allPatients}: IPopUp) => {
  const InfusionText = (
    <SimpleGrid columns={2} spacing={10} w={500} h="fit-content">
      <Box>
        {allPatients ? (
          <Text mb="20px">
            Il grafico mostra il tempo dedicato dal personale ospedaliero in un
            anno per la gestione di tutti i pazienti in trattamento con un
            farmaco EV o SC considerando le percentuali dei due diversi
            trattamenti negli scenari «Oggi» e «Domani»
          </Text>
        ) : (
          <Text mb="20px">
            Il grafico mostra il tempo dedicato dal personale ospedaliero in un
            anno per la gestione di un paziente in trattamento con una terapia
            EV o SC.
          </Text>
        )}
        <Text mb="20px">
          Il percorso del paziente comprende le seguenti attività eseguite dal
          personale ospedaliero:
        </Text>
        <Text variant="bodySmallBold">Paziente EV</Text>
        <UnorderedList mb="20px">
          <ListItem>
            <Text>
              Accessi in ospedale per la somministrazione della terapia
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita pre-somministrazione terapia</Text>
              </ListItem>
              <ListItem>
                <Text>- Consegna terapia in reparto</Text>
              </ListItem>
              <ListItem>
                <Text>- Preparazione del farmaco</Text>
              </ListItem>
              <ListItem>
                <Text>- Infusione e osservazione*</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <Text>
              Accessi in ospedale per le visite di controllo (opzionale)
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita di controllo</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Text variant="bodySmallBold">Paziente SC</Text>
        <UnorderedList>
          <ListItem>
            <Text>Accessi in farmacia per il ritiro della terapia</Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Ritiro della terapia in farmacia</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box>
        <UnorderedList mb="20px">
          <ListItem>
            <Text>
              Accessi in ospedale per le visite di controllo (opzionale)
            </Text>
            <UnorderedList listStyleType="none">
              <ListItem>
                <Text>- Accettazione</Text>
              </ListItem>
              <ListItem>
                <Text>- Visita di controllo</Text>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Text mb="20px">
          *Nota: il tempo di infusione e osservazione per il personale
          ospedaliero è generalmente minore del tempo in cui il paziente rimane
          in poltrona.
        </Text>
        <Text>
          Per ottenere i costi del personale, il tempo dei singoli
          professionisti è stato valorizzato per le rispettive RAL indicate di
          seguito:
        </Text>
        <OrderedList>
          <ListItem>
            <Text>
              RAL Clinico: 85.607 €/anno (ARAN 2014, Retribuzioni medie nella
              pubblica amministrazione)
            </Text>
          </ListItem>
          <ListItem>
            <Text>RAL Infermiere: 36.330 €/anno (OCSE 2020)</Text>
          </ListItem>
          <ListItem>
            <Text>RAL Farmacista: 53.947 €/anno (PGEU 2015)</Text>
          </ListItem>
          <ListItem>
            <Text>
              RAL Amministrativo: 27.041 €/anno (ARAN 2014, Retribuzioni medie
              nella pubblica amministrazione)
            </Text>
          </ListItem>
        </OrderedList>
        <Text>
          Considerando 220 giorni lavorativi/anno e 8 ore lavorative/giorno
        </Text>
      </Box>
    </SimpleGrid>
  );

  return (
    <PopUp
      trigger={<IconSvg icon="info" size="40px" />}
      message={InfusionText}
      placement="bottom-end"
      mx={0}
      my={0}
    />
  );
};

export default InfoPopUp;
