import {Text as ChakraText, useStyleConfig} from '@chakra-ui/react';

import {IProps} from './interfaces';

const Text = ({variant = 'bodySmall', color, children, ...props}: IProps) => {
  const styles = useStyleConfig('typography', {variant});

  return (
    <ChakraText sx={styles} color={color} {...props}>
      {children}
    </ChakraText>
  );
};

export default Text;
