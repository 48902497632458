import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  InputDataContextProps,
  InputDataProviderProps,
  ValidationErrors,
} from '@contexts/inputData/definitions';
import {CommonData, PatientData, ScenariosData, StaffData} from '@definitions';
import {
  commonDefaults,
  patientDefaults,
  scenariosDefaults,
  staffDefaults,
} from '@config';

const undefinedMethod = () => {
  throw new Error('[InputDataContext] Context not initialised');
};

const InputDataContext = createContext<InputDataContextProps>({
  patientData: {current: patientDefaults},
  staffData: {current: staffDefaults},
  commonData: {current: commonDefaults},
  scenariosData: {current: scenariosDefaults},
  updatePatientData: undefinedMethod,
  updateStaffData: undefinedMethod,
  updateCommonData: undefinedMethod,
  updateScenariosData: undefinedMethod,
  validationErrors: undefined,
  setValidationErrors: undefinedMethod,
  hasValidationErrors: false,
});

export const InputDataProvider = ({children}: InputDataProviderProps) => {
  const patientData = useRef<PatientData>(patientDefaults);
  const staffData = useRef<StaffData>(staffDefaults);
  const commonData = useRef<CommonData>(commonDefaults);
  const scenariosData = useRef<ScenariosData>(scenariosDefaults);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>();

  const hasValidationErrors = useMemo(() => {
    return validationErrors !== undefined
      ? Object.keys(validationErrors).length > 0
      : false;
  }, [validationErrors]);

  const updatePatientData = useCallback<
    InputDataContextProps['updatePatientData']
  >(newData => {
    patientData.current = newData;
  }, []);

  const updateStaffData = useCallback<InputDataContextProps['updateStaffData']>(
    newData => {
      staffData.current = newData;
    },
    [],
  );

  const updateCommonData = useCallback<
    InputDataContextProps['updateCommonData']
  >(newData => {
    const currentData = commonData.current;
    commonData.current = {
      ...currentData,
      ...newData,
    };
  }, []);

  const updateScenariosData = useCallback<
    InputDataContextProps['updateScenariosData']
  >(newData => {
    const currentData = scenariosData.current;

    scenariosData.current = {
      ...currentData,
      ...newData,
    };
  }, []);

  const memoizedValues = useMemo(() => {
    return {
      updatePatientData,
      updateStaffData,
      updateCommonData,
      updateScenariosData,
      validationErrors,
      setValidationErrors,
      hasValidationErrors,
    };
  }, [
    updateCommonData,
    updatePatientData,
    updateScenariosData,
    updateStaffData,
    validationErrors,
    setValidationErrors,
    hasValidationErrors,
  ]);

  return (
    <InputDataContext.Provider
      value={{
        patientData,
        staffData,
        commonData,
        scenariosData,
        ...memoizedValues,
      }}>
      {children}
    </InputDataContext.Provider>
  );
};

export const useInputDataContext = (): InputDataContextProps =>
  useContext(InputDataContext);
