import {ChangeEventHandler, useCallback, useState} from 'react';

import {useInputDataContext} from '@contexts/inputData';
import {CommonData} from '@definitions';

import {
  InputValue,
  UseCommonSwitchProps,
  UseCommonSwitchReturn,
} from './definitions';

const useCommonSwitch = (
  key: keyof CommonData,
  {initialValue = false}: UseCommonSwitchProps,
): UseCommonSwitchReturn => {
  const {updateCommonData} = useInputDataContext();
  const [inputValue, setInputValue] = useState<InputValue>(initialValue);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const updatedValue = event.target.checked;

      // Update internal state value
      setInputValue(updatedValue);

      // Update context value
      updateCommonData({[key]: updatedValue});
    },
    [key, updateCommonData],
  );

  const registerProps = useCallback(() => {
    return {
      isChecked: inputValue,
      onChange: handleChange,
    };
  }, [handleChange, inputValue]);

  return [inputValue, registerProps];
};

export default useCommonSwitch;
