import {StylesObject} from '@definitions';

export const styles: StylesObject = {
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
};
