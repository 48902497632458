import React, {useMemo} from 'react';
import {Select} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {roleSelectOptions} from '@config';

import {RoleSelectProps} from './definitions';

const RoleSelect = React.forwardRef<HTMLSelectElement, RoleSelectProps>(
  (props, ref) => {
    const {excludedRoles, ...otherProps} = props;

    const filteredOptions = useMemo(() => {
      if (excludedRoles && excludedRoles.length > 0) {
        return roleSelectOptions.filter(opt => {
          return !excludedRoles.includes(opt.value);
        });
      }

      return roleSelectOptions;
    }, [excludedRoles]);

    return (
      <Select
        ref={ref}
        borderColor="neutral.200"
        width={170}
        color="primary.main"
        textTransform="uppercase"
        fontSize={13}
        icon={<IconSvg icon="down_arrow" />}
        iconSize="10px"
        focusBorderColor="primary.main"
        {...otherProps}>
        {filteredOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    );
  },
);

export default RoleSelect;
