export enum Color {
  BaseBlack = '#000000',
  BaseWhite = '#FFFFFF',
  // Primary
  Primary = '#F26633',
  //Accent
  Accent = '#00B8AE',
  //Link
  Link = '#4F5CD6',
  // Gray
  Gray50 = '#F0EEED',
  Gray100 = '#E6E6E6',
  Gray200 = '#959595',
  //Chars
  FreeChairs = '#72A8A3',
  OccupiedChairs = '#02736E',
  Nurse = '#00B8AD',
  Doctor = '#E21860',
  Pharmacist = '#69B445',
  Administrator = '#DC4133',
  EvLight = '#CA8E9E',
  Ev = '#862045',
  ScLight = '#85A977',
  Sc = '#436632',
  Activity = '#2444A2',
}

export enum FontSize {
  HeadlineLarge = '42px',
  HeadlineSmall = '12px',
  TitleLarge = '18px',
  TitleSmall = '14px',
  BodyMedium = '15px',
  BodySmall = '13px',
  BodyXSmall = '10px',
}

export enum LineHeight {
  HeadlineLarge = '50px',
  HeadlineSmall = '11px',
  TitleLarge = '22px',
  TitleSmall = '12px',
  BodyMedium = '16px',
  BodySmall = '16px',
  BodyXSmall = '12px',
}

export enum FontFamily {
  Regular = 'GSKPrecisionRegular',
  RegularItalic = 'GSKPrecisionRegularItalic',
  Bold = 'GSKPrecisionBold',
  BoldItalic = 'GSKPrecisionBoldItalic',
  Light = 'GSKPrecisionLight',
  LightItalic = 'GSKPrecisionLightItalic',
  Thin = 'GSKPrecisionThin',
  ThinItalic = 'GSKPrecisionThinItalic',
}
