import {StylesObject} from '@definitions';

export const styles: StylesObject = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: '25px 0px',
  },
  content: {
    flex: 1,
    position: 'relative',
    overflow: 'auto',
  },
  top: {
    flex: '0 0 auto',
  },
};
