import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useDisclosure} from '@chakra-ui/react';

import {useInputDataContext} from '@contexts/inputData';
import {ValidationModal} from '@molecules/validationModal';

import {
  Section,
  SectionsContextProps,
  SectionsProviderProps,
  Subsection,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[SectionsContext] Context not initialised');
};

const SectionsContext = createContext<SectionsContextProps>({
  activeSection: Section.INPUT_DATA,
  activeSubsection: Subsection.FIRST_STEP,
  hasPrevSection: false,
  hasNextSection: true,
  goToSection: undefinedMethod,
  goToPrev: undefinedMethod,
  goToNext: undefinedMethod,
});

const sectionList = [
  Section.INPUT_DATA,
  Section.INFUSION_CENTER_RESULTS,
  Section.HOSPITAL_RESULTS,
  Section.PATIENT_RESULTS,
];

const subsectionList = [Subsection.FIRST_STEP, Subsection.SECOND_STEP];

export const SectionsProvider = ({children}: SectionsProviderProps) => {
  const {validationErrors, hasValidationErrors} = useInputDataContext();

  // Handle modal state
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const [activeSection, setActiveSection] = useState<Section>(
    Section.INPUT_DATA,
  );

  const [activeSubsection, setActiveSubsection] = useState<
    Subsection | undefined
  >(Subsection.FIRST_STEP);

  const [hasPrevSection, setHasPrevSection] = useState<boolean>(false);
  const [hasNextSection, setHasNextSection] = useState<boolean>(true);

  const goToSection = useCallback(
    (newSection: Section) => {
      if (activeSection === newSection) return;

      if (hasValidationErrors) {
        onModalOpen();
        return;
      }

      setActiveSection(newSection);

      if (newSection === Section.INPUT_DATA) {
        setActiveSubsection(Subsection.FIRST_STEP);
      } else {
        setActiveSubsection(undefined);
      }
    },
    [activeSection, hasValidationErrors, onModalOpen],
  );

  const shiftSection = useCallback(
    (direction: 'prev' | 'next') => {
      const currentSectionIndex = sectionList.indexOf(activeSection);

      // If active section is Input section
      if (activeSection === Section.INPUT_DATA) {
        const currentSubsectionIndex = subsectionList.indexOf(
          activeSubsection!,
        );

        const newSubsectionIndex =
          currentSubsectionIndex + (direction === 'next' ? 1 : -1);

        if (direction === 'prev' && newSubsectionIndex < 0) {
          return;
        } else if (
          direction === 'next' &&
          newSubsectionIndex > subsectionList.length - 1
        ) {
          if (hasValidationErrors) {
            onModalOpen();
            return;
          }

          setActiveSection(sectionList[1]);
          setActiveSubsection(undefined);
        } else {
          setActiveSubsection(subsectionList[newSubsectionIndex]);
        }
      } else {
        const newSectionIndex =
          currentSectionIndex + (direction === 'next' ? 1 : -1);

        let newSection;

        if (direction === 'next' && newSectionIndex > sectionList.length - 1) {
          return;
        } else {
          newSection = sectionList[newSectionIndex];
          setActiveSection(newSection);
        }

        if (newSection === Section.INPUT_DATA) {
          setActiveSubsection(subsectionList[subsectionList.length - 1]);
        }
      }
    },
    [activeSection, activeSubsection, hasValidationErrors, onModalOpen],
  );

  const goToNext = useCallback(() => {
    shiftSection('next');
  }, [shiftSection]);

  const goToPrev = useCallback(() => {
    shiftSection('prev');
  }, [shiftSection]);

  const memoizedValues = useMemo(() => {
    return {
      activeSection,
      activeSubsection,
      hasPrevSection,
      hasNextSection,
      goToSection,
      goToNext,
      goToPrev,
    };
  }, [
    activeSection,
    activeSubsection,
    goToNext,
    goToPrev,
    goToSection,
    hasNextSection,
    hasPrevSection,
  ]);

  useEffect(() => {
    if (
      activeSection === Section.INPUT_DATA &&
      activeSubsection === Subsection.FIRST_STEP
    ) {
      setHasPrevSection(false);
      setHasNextSection(true);
    } else if (activeSection === Section.PATIENT_RESULTS) {
      setHasPrevSection(true);
      setHasNextSection(false);
    } else {
      setHasPrevSection(true);
      setHasNextSection(true);
    }
  }, [activeSection, activeSubsection]);

  return (
    <SectionsContext.Provider value={memoizedValues}>
      {children}
      <ValidationModal
        errors={validationErrors ?? {}}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </SectionsContext.Provider>
  );
};

export const useSectionsContext = (): SectionsContextProps =>
  useContext(SectionsContext);
