import {Box, Image} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';
import table from '@theme/tabella-infusione.svg';

const InfoPopUp = () => {
  const InfusionText = (
    <Box w={325} textAlign="left">
      <Text variant="bodySmallBold" as="span">
        OPZIONE 1 (Default)
      </Text>
      <Text mb="20px">
        Il tempo medio di infusione di un paziente generico gestito nel centro
        infusionale è di 57 min, calcolato considerando la media pesata sulla
        prevalenza della patologia del tempo di infusione delle terapie nella
        tabella sottostante.
      </Text>
      <Text variant="bodySmallBold" as="span">
        OPZIONE 2
      </Text>
      <Text mb="30px">
        Il tempo medio di infusione di un paziente generico gestito nel centro
        infusionale è di 126 min, calcolato considerando la media semplice dei
        tempi di infusioni delle terapie nella tabella sottostante.
      </Text>
      <Image src={table} />
    </Box>
  );

  return (
    <PopUp
      trigger={<IconSvg icon="info" size="40px" />}
      message={InfusionText}
      placement="left-start"
    />
  );
};

export default InfoPopUp;
