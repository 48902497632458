import {Flex, Grid, GridItem} from '@chakra-ui/react';

import {CustomNumberInput} from '@atoms/inputNumber';
import {Switch} from '@atoms/Switch';
import {Text} from '@atoms/Text';

import {IProps} from './interfaces';

const HospitalGrid = ({
  allPatients,
  allPatientsRegister,
  checkUpIncludedRegister,
  todaySc,
  todayEv,
  tomorrowSc,
  tomorrowEv,
  patients,
}: IProps) => {
  return (
    <Flex
      w={265}
      bg="neutral.100"
      justifyContent="flex-start"
      flexDirection="column"
      alignItems="center"
      padding="25px 15px"
      gap={30}
      height="100%">
      <Switch
        id="patient"
        leftLabel="Singolo paziente"
        rightLabel="Tutti i pazienti"
        {...allPatientsRegister}
      />
      <Switch
        id="visit"
        leftLabel="Senza visita controllo"
        rightLabel="Con visita controllo"
        {...checkUpIncludedRegister}
      />
      {allPatients && (
        <Grid
          height="180px"
          templateRows="repeat(5, 1fr)"
          templateColumns="repeat(4, 1fr)"
          alignItems="center"
          gap={2}>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text variant="bodyMedium">SC</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text variant="bodyMedium">EV</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
          <GridItem rowSpan={1} colSpan={1} textAlign="right">
            <Text>OGGI</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...todaySc} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...todayEv} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text>%</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} textAlign="right">
            <Text>DOMANI</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <CustomNumberInput {...tomorrowSc} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <CustomNumberInput {...tomorrowEv} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} justifySelf="center">
            <Text>%</Text>
          </GridItem>
          <GridItem
            rowSpan={1}
            colSpan={2}
            justifySelf="center"
            textAlign="right">
            <Text>N° pazienti in trattamento</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <CustomNumberInput {...patients} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} />
        </Grid>
      )}
    </Flex>
  );
};

export default HospitalGrid;
