import {Control, FieldErrors, UseFormRegister} from 'react-hook-form';
import {Flex} from '@chakra-ui/react';

import {Fields} from '@organisms/inputSection/definitions';
import {SecondStepGrid} from '@molecules/grid/inputData';

const SecondStep = ({
  register,
  control,
  errors,
}: {
  register: UseFormRegister<Fields>;
  control: Control<Fields>;
  errors: FieldErrors<Fields>;
}) => {
  return (
    <Flex>
      <SecondStepGrid register={register} control={control} errors={errors} />
    </Flex>
  );
};

export default SecondStep;
