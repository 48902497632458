import {useCallback, useState} from 'react';

import {useInputDataContext} from '@contexts/inputData';
import {CommonData} from '@definitions';

import {
  InputValue,
  UseCommonInputProps,
  UseCommonInputReturn,
} from './definitions';

const useCommonInput = (
  key: keyof CommonData,
  {initialValue = null}: UseCommonInputProps,
): UseCommonInputReturn => {
  const {updateCommonData} = useInputDataContext();
  const [inputValue, setInputValue] = useState<InputValue>(initialValue);

  const handleChange = useCallback(
    (value: string) => {
      const updatedValue = value !== '' ? Number(value) : null;

      // Update internal state value
      setInputValue(updatedValue);

      // Update context value
      updateCommonData({[key]: updatedValue});
    },
    [key, updateCommonData],
  );

  const registerProps = useCallback(() => {
    return {
      value: inputValue ?? '',
      onChange: handleChange,
    };
  }, [handleChange, inputValue]);

  return [inputValue, registerProps];
};

export default useCommonInput;
