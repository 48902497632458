import {Global} from '@emotion/react';

import bold from '@theme/assets/fonts/GSKPrecision-Bold.woff2';
import boldItalic from '@theme/assets/fonts/GSKPrecision-BoldItalic.woff2';
import light from '@theme/assets/fonts/GSKPrecision-Light.woff2';
import lightItalic from '@theme/assets/fonts/GSKPrecision-LightItalic.woff2';
import regular from '@theme/assets/fonts/GSKPrecision-Regular.woff2';
import regularItalic from '@theme/assets/fonts/GSKPrecision-RegularItalic.woff2';
import thin from '@theme/assets/fonts/GSKPrecision-Thin.woff2';
import thinItalic from '@theme/assets/fonts/GSKPrecision-ThinItalic.woff2';

const Fonts = () => {
  return (
    <Global
      styles={`
        @font-face {
          font-family: 'GSKPrecisionRegularItalic';
          src: url('${regularItalic}') format('woff2');
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionThin';
          src: url('${thin}') format('woff2');
          font-weight: 100;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionLight';
          src: url('${light}') format('woff2');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionThinItalic';
          src: url('${thinItalic}') format('woff2');
          font-weight: 100;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionRegular';
          src: url('${regular}') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionBoldItalic';
          src: url('${boldItalic}') format('woff2');
          font-weight: bold;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionLightItalic';
          src: url('${lightItalic}') format('woff2');
          font-weight: 300;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'GSKPrecisionBold';
          src: url('${bold}') format('woff2');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
      `}
    />
  );
};

export default Fonts;
