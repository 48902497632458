import {useMemo} from 'react';
import {Box} from '@chakra-ui/react';

import {useSectionsContext} from '@contexts/sections';
import {Section} from '@contexts/sections/definitions';
import {styles} from '@organisms/content/styles';
import {HospitalSection} from '@organisms/hospitalSection';
import {InfusionCenterSection} from '@organisms/infusionCenterSection';
import {InputSection} from '@organisms/inputSection';
import {PatientsSection} from '@organisms/patientsSection';
import {Navigation} from '@molecules/navigation';

const Content = () => {
  const {activeSection} = useSectionsContext();

  const sectionComponent = useMemo(() => {
    switch (activeSection) {
      case Section.INPUT_DATA:
        return <InputSection />;
      case Section.INFUSION_CENTER_RESULTS:
        return <InfusionCenterSection />;
      case Section.HOSPITAL_RESULTS:
        return <HospitalSection />;
      case Section.PATIENT_RESULTS:
        return <PatientsSection />;
      default:
        return null;
    }
  }, [activeSection]);

  const {title, subtitle} = useMemo<{title: string; subtitle: string}>(() => {
    let subtitle = '';
    let title = '';

    switch (activeSection) {
      case Section.INPUT_DATA:
        subtitle = 'Input';
        title = 'Dati di Input';
        break;
      case Section.INFUSION_CENTER_RESULTS:
        subtitle = 'Risultati: Centro Infusionale';
        title = 'Produttività Aggiuntiva';
        break;
      case Section.HOSPITAL_RESULTS:
        subtitle = 'Risultati: Ospedale';
        title = 'Prospettiva Ospedale';
        break;
      case Section.PATIENT_RESULTS:
        subtitle = 'Risultati: Paziente';
        title = 'Prospettiva Paziente';
        break;
    }

    return {title, subtitle};
  }, [activeSection]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.top}>
        <Navigation subtitle={subtitle} title={title} />
      </Box>
      <Box sx={styles.content}>{sectionComponent}</Box>
    </Box>
  );
};

export default Content;
