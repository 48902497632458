import {Color} from './variables';

export const colors = {
  common: {
    black: Color.BaseBlack,
    white: Color.BaseWhite,
  },
  primary: {
    main: Color.Primary,
  },
  secondary: {
    main: Color.Accent,
  },
  link: {
    main: Color.Link,
  },
  neutral: {
    50: Color.Gray50,
    100: Color.Gray100,
    200: Color.Gray200,
  },
  chars: {
    FreeChairs: Color.FreeChairs,
    OccupiedChairs: Color.OccupiedChairs,
    Nurse: Color.Nurse,
    Doctor: Color.Doctor,
    Pharmacist: Color.Pharmacist,
    Administrator: Color.Administrator,
    EvLight: Color.EvLight,
    Ev: Color.Ev,
    ScLight: Color.ScLight,
    Sc: Color.Sc,
    Activity: Color.Activity,
    Waiting: Color.Administrator,
    Moving: Color.Nurse,
  },
};
