import {Image} from '@chakra-ui/react';

import gsk_signal from '@theme/GSK_Signal.png';
import logo from '@theme/logo.png';

import {IProps} from './interfaces';

const Logo = ({variant}: IProps) => {
  const src = variant === 'logo' ? logo : gsk_signal;
  const width = variant === 'logo' ? 120 : 70;
  return <Image src={src} width={width} />;
};

export default Logo;
