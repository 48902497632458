import {extendTheme} from '@chakra-ui/react';

import {colors} from '@theme/palette';
import {typography} from '@theme/typography';

// Create a theme instance.
export const theme = extendTheme({
  colors,
  components: {
    typography,
  },
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
      },
    },
  },
});
