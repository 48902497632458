import {useMemo} from 'react';
import {Image} from '@chakra-ui/react';

import acronyms from '@theme/assets/icons/acronimi.svg';
import attention from '@theme/assets/icons/attenzione.svg';
import down_arrow from '@theme/assets/icons/down_arrow.png';
import sources from '@theme/assets/icons/fonti.svg';
import graffa_orange from '@theme/assets/icons/graffa-ORANGE.svg';
import graffa from '@theme/assets/icons/graffa.svg';
import info from '@theme/assets/icons/info.svg';
import hypothesis from '@theme/assets/icons/ipotesi.svg';
import next_arrow from '@theme/assets/icons/next_arrow.svg';
import prev_arrow from '@theme/assets/icons/prev_arrow.svg';

import {IProps} from './interfaces';

const IconSvg = ({icon, onClick, size = '40px', style}: IProps) => {
  const source = useMemo(() => {
    switch (icon) {
      case 'acronyms':
        return acronyms;
      case 'attention':
        return attention;
      case 'sources':
        return sources;
      case 'graffa_orange':
        return graffa_orange;
      case 'graffa':
        return graffa;
      case 'info':
        return info;
      case 'hypothesis':
        return hypothesis;
      case 'next_arrow':
        return next_arrow;
      case 'prev_arrow':
        return prev_arrow;
      case 'down_arrow':
        return down_arrow;
    }
  }, [icon]);

  return (
    <Image
      src={source}
      onClick={onClick}
      maxWidth="none"
      width={size}
      height={size}
      sx={style}
    />
  );
};

export default IconSvg;
