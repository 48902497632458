export const percentageToDecimal = (value: number) => {
  return value / 100;
};

export const decimalToPercentage = (value: number) => {
  return value * 100;
};

export const clampMin = (value: number, min: number): number => {
  return Math.max(value, min);
};

export const clampMax = (value: number, max: number): number => {
  return Math.min(value, max);
};

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const percentageClamp = (value: number): number => {
  return clamp(value, 0, 100);
};

export const round = (number: number, decimals: number = 0) => {
  const d = Math.pow(10, decimals);
  return Math.round((number + Number.EPSILON) * d) / d;
};
