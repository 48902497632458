import {
  FormControl,
  FormLabel,
  Switch as ChackraSwitch,
} from '@chakra-ui/react';

import {Text} from '@atoms/Text';

import {IProps} from './interfaces';
import {styles} from './styles';

const Switch = ({id, leftLabel, rightLabel, isChecked, onChange}: IProps) => {
  return (
    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="center"
      id={id}>
      <FormLabel
        mb="0"
        textAlign="right"
        fontSize={13}
        sx={isChecked ? styles.label : styles.label__active}>
        <Text variant="bodyXSmall">{leftLabel}</Text>
      </FormLabel>
      {/* @TODO: Give correct size, update them size value */}
      <ChackraSwitch
        size="lg"
        isChecked={isChecked}
        onChange={onChange}
        sx={styles.switchContainer}
      />
      <FormLabel
        mb="0"
        textAlign="left"
        fontSize={13}
        sx={isChecked ? styles.label__active : styles.label}>
        <Text variant="bodyXSmall">{rightLabel}</Text>
      </FormLabel>
    </FormControl>
  );
};

export default Switch;
