import {Control, FieldErrors, UseFormRegister} from 'react-hook-form';
import {Grid, GridItem} from '@chakra-ui/react';

import {ControlledNumberInput} from '@organisms/inputSection/controlledNumberInput';
import {Fields} from '@organisms/inputSection/definitions';
import {RoleSelect} from '@atoms/roleSelect';
import {Text} from '@atoms/Text';

import ErrorPopUp from './errorPopUp';
import InfusionPopUp from './infusionPopUp';

const SecondStepGrid = ({
  register,
  control,
  errors,
}: {
  register: UseFormRegister<Fields>;
  control: Control<Fields>;
  errors: FieldErrors<Fields>;
}) => {
  return (
    <Grid
      width={665}
      height="540px"
      templateRows="repeat(9, 1fr)"
      templateColumns="repeat(8, 1fr)"
      margin="0px 20px"
      alignItems="center"
      gap={1}>
      {
        //FIRST ROW
      }
      <GridItem rowSpan={1} colSpan={6} justifySelf="center">
        <Text
          variant="titleLarge"
          color="neutral.200"
          textTransform="uppercase"
          textAlign="right">
          Tempo di gestione del paziente da parte del personale ospedaliero
        </Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={2} />
      {
        //SECOND ROW
      }
      <GridItem rowSpan={1} colSpan={4} />
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <Text variant="titleSmall">EV</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <Text variant="titleSmall">SC</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <Text variant="titleSmall">PROFESSIONISTA</Text>
      </GridItem>
      {
        //THIRD ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {(errors.acceptanceTime?.ev || errors.acceptanceTime?.sc) && (
          <ErrorPopUp />
        )}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Accettazione</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="acceptanceTime.ev"
          control={control}
          isInvalid={Boolean(errors.acceptanceTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="acceptanceTime.sc"
          control={control}
          isInvalid={Boolean(errors.acceptanceTime?.sc)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('acceptanceTime.role')}
          isInvalid={Boolean(errors.acceptanceTime?.role)}
          excludedRoles={['pharmacist']}
        />
      </GridItem>
      {
        //FOURTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {errors.preDosingVisitTime?.ev && <ErrorPopUp placement="right" />}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Visita pre-somministrazione terapia</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="preDosingVisitTime.ev"
          control={control}
          isInvalid={Boolean(errors.preDosingVisitTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('preDosingVisitTime.role')}
          isInvalid={Boolean(errors.preDosingVisitTime?.role)}
          excludedRoles={['pharmacist', 'administrative']}
        />
      </GridItem>
      {
        //FIFTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {errors.therapyDeliveryTime?.ev && <ErrorPopUp placement="right" />}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Consegna terapia in reparto</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="therapyDeliveryTime.ev"
          control={control}
          isInvalid={Boolean(errors.therapyDeliveryTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('therapyDeliveryTime.role')}
          isInvalid={Boolean(errors.therapyDeliveryTime?.role)}
        />
      </GridItem>
      {
        //SIXTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {errors.medicinePreparationTime?.ev && <ErrorPopUp />}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Preparazione del farmaco</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="medicinePreparationTime.ev"
          control={control}
          isInvalid={Boolean(errors.medicinePreparationTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('medicinePreparationTime.role')}
          isInvalid={Boolean(errors.medicinePreparationTime?.role)}
          excludedRoles={['administrative']}
        />
      </GridItem>
      {
        //SEVENTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {errors.infusionObservationTime?.ev && <ErrorPopUp />}
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={3}
        display="flex"
        alignItems="center"
        gap={3}
        justifyContent="flex-end">
        <InfusionPopUp />
        <Text>Infusione e osservazione</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="infusionObservationTime.ev"
          control={control}
          isInvalid={Boolean(errors.infusionObservationTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('infusionObservationTime.role')}
          isInvalid={Boolean(errors.infusionObservationTime?.role)}
          excludedRoles={['administrative', 'pharmacist']}
        />
      </GridItem>
      {
        //EIGHTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {errors.therapyPickupTime?.sc && <ErrorPopUp />}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Ritiro terapia in farmacia</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="therapyPickupTime.sc"
          control={control}
          isInvalid={Boolean(errors.therapyPickupTime?.sc)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('therapyPickupTime.role')}
          isInvalid={Boolean(errors.therapyPickupTime?.role)}
        />
      </GridItem>
      {
        //NINTH ROW
      }
      <GridItem rowSpan={1} colSpan={1} justifySelf="flex-end">
        {(errors.checkupTime?.ev || errors.checkupTime?.sc) && <ErrorPopUp />}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} textAlign="right">
        <Text>Visita di controllo</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="checkupTime.ev"
          control={control}
          isInvalid={Boolean(errors.checkupTime?.ev)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} justifySelf="center">
        <ControlledNumberInput
          name="checkupTime.sc"
          control={control}
          isInvalid={Boolean(errors.checkupTime?.sc)}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Text>min</Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <RoleSelect
          {...register('checkupTime.role')}
          isInvalid={Boolean(errors.checkupTime?.role)}
          excludedRoles={['administrative', 'pharmacist']}
        />
      </GridItem>
    </Grid>
  );
};

export default SecondStepGrid;
