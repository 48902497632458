import React from 'react';
import {Controller} from 'react-hook-form';

import {CustomNumberInput} from '@atoms/inputNumber';

import {ControlledNumberInputProps} from './definitions';

const ControlledNumberInput = ({
  name,
  control,
  ...otherProps
}: ControlledNumberInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {value, onChange, ...otherFieldProps}}) => (
        <CustomNumberInput
          onChange={value => onChange(parseFloat(value))}
          // @ts-ignore
          value={isNaN(value) ? '' : value}
          {...otherFieldProps}
          {...otherProps}
        />
      )}
    />
  );
};

export default ControlledNumberInput;
