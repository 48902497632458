import {useCallback, useState} from 'react';

import {useInputDataContext} from '@contexts/inputData';
import {CustomNumberInputProps} from '@atoms/inputNumber/definitions';
import {
  UseScenariosInputsProps,
  UseScenariosInputsReturn,
} from '@hooks/useScenariosInputs/definitions';
import {Scenarios, ScenariosData} from '@definitions';

const useScenariosInputs = ({
  initialData,
}: UseScenariosInputsProps): UseScenariosInputsReturn => {
  const {updateScenariosData} = useInputDataContext();

  // Store scenarios data inputs
  const [scenarios, setScenarios] = useState<ScenariosData>(initialData);

  // Handle scenarios inputs change
  const getHandleChangeScenarios = useCallback<
    (scenario: Scenarios) => (value: string) => void
  >(
    scenario => {
      return value => {
        let scValue: number | null;
        let evValue: number | null;

        if (value === '') {
          scValue = null;
          evValue = null;
        } else {
          scValue = Number(value);
          scValue = Math.min(Math.max(scValue, 0), 100);
          evValue = 100 - scValue;
        }

        const updatedData = {
          [scenario]: {
            sc: scValue,
            ev: evValue,
          },
        };

        // Update internal state value
        setScenarios(prevState => {
          return {
            ...prevState,
            ...updatedData,
          };
        });

        // Update context value
        updateScenariosData(updatedData);
      };
    },
    [updateScenariosData],
  );

  const getScenariosInputProps = useCallback<
    UseScenariosInputsReturn['getScenariosInputProps']
  >(
    (scenario, therapy) => {
      let props: CustomNumberInputProps = {
        value: scenarios[scenario][therapy] ?? '',
        min: 0,
        max: 100,
      };

      if (therapy === 'sc') {
        props = {
          ...props,
          onChange: getHandleChangeScenarios(scenario),
        };
      }

      if (therapy === 'ev') {
        props = {...props, isDisabled: true, isReadOnly: true};
      }

      return props;
    },
    [getHandleChangeScenarios, scenarios],
  );

  return {
    scenarios,
    getScenariosInputProps,
  };
};

export default useScenariosInputs;
