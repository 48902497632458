import {
  FilledScenariosData,
  FixedInputData,
  PatientData,
  Scenarios,
  StaffData,
  Therapies,
} from '@definitions';

import {decimalToPercentage, percentageToDecimal} from '../../math';

import {
  WorkingTime,
  CheckupWorkingTimeLost,
  DosingWorkingTimeLost,
  TherapyPickupWorkingTimeLost,
  PatientResultsForSinglePatient,
  PatientResultsPerYear,
  PartialScenariosResultsPerYear,
  ScenariosResultsPerYear,
  PatientResultsForAllPatients,
  ResultData,
} from './definitions';

export const getDosingWorkingTimeLost = (
  patientInputData: PatientData,
  staffInputData: StaffData,
  fixedInputData: FixedInputData,
  houseToHospitalTime: number,
): DosingWorkingTimeLost => {
  const activityEv =
    staffInputData.acceptanceTime.ev! +
    staffInputData.preDosingVisitTime.ev! +
    patientInputData.chairOccupationTime.ev!;
  const waitingEv = patientInputData.totalPermanenceTime.ev! - activityEv;
  const movingEv = houseToHospitalTime * 2;
  const totalHoursEv =
    (activityEv + waitingEv + movingEv) / fixedInputData.workingMinutesPerHour;

  return {
    ev: {
      activity: activityEv,
      waiting: waitingEv,
      moving: movingEv,
      total: {
        hours: totalHoursEv,
        days: totalHoursEv > 4 ? 1 : 0.5,
      },
    },
    sc: {
      activity: fixedInputData.scDosingTimeByPatient,
      waiting: 0,
      moving: 0,
      total: {
        hours: 0,
        days: 0,
      },
    },
  };
};

export const getCheckupWorkingTimeLost = (
  patientInputData: PatientData,
  staffInputData: StaffData,
  fixedInputData: FixedInputData,
  houseToHospitalTime: number,
): CheckupWorkingTimeLost => {
  const getTimeData = (therapy: Therapies): WorkingTime => {
    const activity =
      staffInputData.acceptanceTime[therapy]! +
      staffInputData.checkupTime[therapy]!;
    const waiting =
      patientInputData.checkupTotalPermanenceTime[therapy]! - activity;
    const moving = houseToHospitalTime * 2;
    const totalHours =
      (activity + waiting + moving) / fixedInputData.workingMinutesPerHour;

    return {
      activity,
      waiting,
      moving,
      total: {hours: totalHours, days: totalHours > 4 ? 1 : 0.5},
    };
  };

  return {
    ev: getTimeData('ev'),
    sc: getTimeData('sc'),
  };
};

export const getTherapyPickupWorkingTimeLost = (
  patientInputData: PatientData,
  staffInputData: StaffData,
  fixedInputData: FixedInputData,
  houseToPharmacyTime: number,
): TherapyPickupWorkingTimeLost => {
  const activitySc = staffInputData.therapyPickupTime.sc!;
  const waitingSC = fixedInputData.scPharmacyWaitingTime;
  const movingSc = houseToPharmacyTime * 2;
  const totalHoursSc =
    (activitySc + waitingSC + movingSc) / fixedInputData.workingMinutesPerHour;

  return {
    ev: {
      activity: 0,
      waiting: 0,
      moving: 0,
      total: {
        hours: 0,
        days: 0,
      },
    },
    sc: {
      activity: activitySc,
      waiting: waitingSC,
      moving: movingSc,
      total: {
        hours: totalHoursSc,
        days: totalHoursSc > 4 ? 1 : 0.5,
      },
    },
  };
};

export const getTotalWorkingTimeLostPerYear = (
  dosingTime: DosingWorkingTimeLost,
  checkupTime: CheckupWorkingTimeLost,
  therapyPickupTime: TherapyPickupWorkingTimeLost,
  patientData: PatientData,
  fixedInputData: FixedInputData,
  checkupIncluded: boolean,
): PatientResultsPerYear => {
  const getTotalTimeData = (
    therapy: Therapies,
    patientData: PatientData,
    checkupIncluded: boolean,
  ) => {
    const dosingCount = patientData.dosingCount[therapy]!;
    const checkupCount = patientData.checkupCount[therapy]!;
    const therapyPickupCount = patientData.therapyPickupCount[therapy]!;

    // Calculate total year minutes of "activity"
    // dosing time + checkup time (if included) + therapy pickup time
    const activityMin =
      dosingTime[therapy].activity * dosingCount +
      (checkupIncluded ? checkupTime[therapy].activity * checkupCount : 0) +
      therapyPickupTime[therapy].activity * therapyPickupCount;

    // Calculate total year minutes of "waiting"
    // dosing time + checkup time (if included) + therapy pickup time
    const waitingMin =
      dosingTime[therapy].waiting * dosingCount +
      (checkupIncluded ? checkupTime[therapy].waiting * checkupCount : 0) +
      therapyPickupTime[therapy].waiting * therapyPickupCount;

    // Calculate total year minutes of "moving"
    // dosing time + checkup time (if included) + therapy pickup time
    const movingMin =
      dosingTime[therapy].moving * dosingCount +
      (checkupIncluded ? checkupTime[therapy].moving * checkupCount : 0) +
      therapyPickupTime[therapy].moving * therapyPickupCount;

    // Convert previous calculated minutes to hours
    const activityHours = activityMin / 60;
    const waitingHours = waitingMin / 60;
    const movingHours = movingMin / 60;

    // Calculate total year hours ("activity" + "waiting" + "moving")
    const totalHours = activityHours + waitingHours + movingHours;

    // Calculate total year days
    const dosingTotalDays = dosingTime[therapy].total.days * dosingCount;
    const checkupTotalDays = checkupIncluded
      ? checkupTime[therapy].total.days * checkupCount
      : 0;
    const therapyPickupTotalDays =
      therapyPickupTime[therapy].total.days * therapyPickupCount;

    const totalDays =
      dosingTotalDays + checkupTotalDays + therapyPickupTotalDays;

    // Calculate total cost
    const totalCost = totalHours * fixedInputData.avarageRalPerHour;

    // Calculate percentages
    const activityPercent = (activityHours * 100) / totalHours;
    const waitingPercent = (waitingHours * 100) / totalHours;
    const movingPercent = (movingHours * 100) / totalHours;

    return {
      activity: {hours: activityHours, percent: activityPercent},
      waiting: {hours: waitingHours, percent: waitingPercent},
      moving: {hours: movingHours, percent: movingPercent},
      total: {
        hours: totalHours,
        days: totalDays,
        cost: totalCost,
      },
    };
  };

  return {
    ev: getTotalTimeData('ev', patientData, checkupIncluded),
    sc: getTotalTimeData('sc', patientData, checkupIncluded),
  };
};

export const getPatientsResultsForSinglePatient = (
  dosingTime: DosingWorkingTimeLost,
  checkupTime: CheckupWorkingTimeLost,
  therapyPickupTime: TherapyPickupWorkingTimeLost,
  patientData: PatientData,
  fixedInputData: FixedInputData,
  checkupIncluded: boolean,
): PatientResultsForSinglePatient => {
  const patientResults = getTotalWorkingTimeLostPerYear(
    dosingTime,
    checkupTime,
    therapyPickupTime,
    patientData,
    fixedInputData,
    checkupIncluded,
  );

  const totalScHours = patientResults.sc.total.hours;
  const totalEvhours = patientResults.ev.total.hours;

  return {
    ...patientResults,
    delta: {
      hoursPercent: decimalToPercentage(
        (totalScHours - totalEvhours) / totalEvhours,
      ),
      cost: patientResults.sc.total.cost - patientResults.ev.total.cost,
      days: patientResults.sc.total.days - patientResults.ev.total.days,
    },
  };
};

const getScenariosPartialResults = (
  singlePatientResults: PatientResultsPerYear,
  scenariosData: FilledScenariosData,
  patientsCount: number,
  workingPatientsPercentage: number,
  patientsWithCaregiverPercentage: number,
  workingCaregiversPercentage: number,
): PartialScenariosResultsPerYear => {
  const scenariosKeys = Object.keys(scenariosData) as Scenarios[];
  let data = {} as PartialScenariosResultsPerYear;

  scenariosKeys.forEach(scenarioKey => {
    const patientEvHours =
      singlePatientResults.ev.total.hours *
      patientsCount *
      percentageToDecimal(workingPatientsPercentage) *
      percentageToDecimal(scenariosData[scenarioKey].ev);

    const caregiverEvHours =
      singlePatientResults.ev.total.hours *
      patientsCount *
      percentageToDecimal(patientsWithCaregiverPercentage) *
      percentageToDecimal(workingCaregiversPercentage) *
      percentageToDecimal(scenariosData[scenarioKey].ev);

    const patientScHours =
      singlePatientResults.sc.total.hours *
      patientsCount *
      percentageToDecimal(workingPatientsPercentage) *
      percentageToDecimal(scenariosData[scenarioKey].sc);

    // Temporray set percent to 0
    data[scenarioKey] = {
      patientEv: {hours: patientEvHours, percent: 0},
      caregiverEv: {hours: caregiverEvHours, percent: 0},
      patientSc: {hours: patientScHours, percent: 0},
      total: patientEvHours + caregiverEvHours + patientScHours,
    };
  });

  return data;
};

export const getScenariosResultsPerYear = (
  singlePatientResults: PatientResultsPerYear,
  scenariosData: FilledScenariosData,
  patientsCount: number,
  workingPatientsPercentage: number,
  patientsWithCaregiverPercentage: number,
  workingCaregiversPercentage: number,
  dosingResultsTime: DosingWorkingTimeLost,
  checkupResultsTime: CheckupWorkingTimeLost,
  checkupIncluded: boolean,
  patientData: PatientData,
): ScenariosResultsPerYear => {
  const partialResults = getScenariosPartialResults(
    singlePatientResults,
    scenariosData,
    patientsCount,
    workingPatientsPercentage,
    patientsWithCaregiverPercentage,
    workingCaregiversPercentage,
  );

  const results = {...partialResults} as ScenariosResultsPerYear;

  // Augment partial result adding caregiver SC results
  // beacause they have different formulas than patient EV, caregiver EV and patient SC
  const getCaregiverScResult = (scenario: Scenarios) => {
    const firstMultiplier =
      patientsCount *
      percentageToDecimal(scenariosData[scenario].sc) *
      percentageToDecimal(patientsWithCaregiverPercentage) *
      percentageToDecimal(workingCaregiversPercentage);

    const secondMultiplier =
      (dosingResultsTime.sc.activity * patientData.dosingCount.sc!) / 60;

    const optionalAddition = checkupIncluded
      ? firstMultiplier *
        checkupResultsTime.sc.total.hours *
        patientData.checkupCount.sc!
      : 0;

    return {
      hours: firstMultiplier * secondMultiplier + optionalAddition,
      // Temporray set percent to 0
      percent: 0,
    };
  };

  const caregiverSc = {
    today: getCaregiverScResult('today'),
    tomorrow: getCaregiverScResult('tomorrow'),
  };

  results.today.caregiverSc = caregiverSc.today;
  results.tomorrow.caregiverSc = caregiverSc.tomorrow;
  results.today.total += caregiverSc.today.hours;
  results.tomorrow.total += caregiverSc.tomorrow.hours;

  const getResultsWithPercent = (scenario: Scenarios, data: ResultData) => {
    data.patientEv.percent = (data.patientEv.hours * 100) / data.total;
    data.caregiverEv.percent = (data.caregiverEv.hours * 100) / data.total;
    data.patientSc.percent = (data.patientSc.hours * 100) / data.total;
    data.caregiverSc.percent = (data.caregiverSc.hours * 100) / data.total;

    return data;
  };

  results.today = getResultsWithPercent('today', results.today);
  results.tomorrow = getResultsWithPercent('tomorrow', results.tomorrow);

  return results;
};

export const getPatientsResultsForAllPatients = (
  singlePatientResults: PatientResultsPerYear,
  scenariosData: FilledScenariosData,
  patientsCount: number,
  workingPatientsPercentage: number,
  patientsWithCaregiverPercentage: number,
  workingCaregiversPercentage: number,
  dosingResultsTime: DosingWorkingTimeLost,
  checkupResultsTime: CheckupWorkingTimeLost,
  checkupIncluded: boolean,
  patientData: PatientData,
  fixedInputData: FixedInputData,
): PatientResultsForAllPatients => {
  const scenariosResults = getScenariosResultsPerYear(
    singlePatientResults,
    scenariosData,
    patientsCount,
    workingPatientsPercentage,
    patientsWithCaregiverPercentage,
    workingCaregiversPercentage,
    dosingResultsTime,
    checkupResultsTime,
    checkupIncluded,
    patientData,
  );

  const hoursRatio =
    (scenariosResults.tomorrow.total - scenariosResults.today.total) /
    scenariosResults.today.total;

  const getGlobalCostResults =
    (): PatientResultsForAllPatients['globalCost'] => {
      const scenarios: Scenarios[] = ['today', 'tomorrow'];
      let data = {} as PatientResultsForAllPatients['globalCost'];

      scenarios.forEach(scenario => {
        const evCost =
          (scenariosResults[scenario].patientEv.hours +
            scenariosResults[scenario].caregiverEv.hours) *
          fixedInputData.avarageRalPerHour;
        const scCost =
          (scenariosResults[scenario].patientSc.hours +
            scenariosResults[scenario].caregiverSc.hours) *
          fixedInputData.avarageRalPerHour;

        data[scenario] = {
          ev: evCost,
          sc: scCost,
          total: evCost + scCost,
        };
      });

      data.delta = data.tomorrow.total - data.today.total;

      return data;
    };

  const getGlobalDaysResults =
    (): PatientResultsForAllPatients['globalDays'] => {
      const getPatient = (scenario: Scenarios): number => {
        return (
          patientsCount *
            percentageToDecimal(workingPatientsPercentage) *
            singlePatientResults.ev.total.days *
            percentageToDecimal(scenariosData[scenario].ev) +
          patientsCount *
            percentageToDecimal(workingPatientsPercentage) *
            singlePatientResults.sc.total.days *
            percentageToDecimal(scenariosData[scenario].sc)
        );
      };

      const getCaregiver = (scenario: Scenarios): number => {
        return (
          patientsCount *
            percentageToDecimal(patientsWithCaregiverPercentage) *
            percentageToDecimal(workingCaregiversPercentage) *
            singlePatientResults.ev.total.days *
            percentageToDecimal(scenariosData[scenario].ev) +
          (checkupIncluded
            ? patientsCount *
              percentageToDecimal(patientsWithCaregiverPercentage) *
              percentageToDecimal(workingCaregiversPercentage) *
              percentageToDecimal(scenariosData[scenario].sc) *
              checkupResultsTime.sc.total.days *
              patientData.checkupCount.sc!
            : 0)
        );
      };

      const scenarios: Scenarios[] = ['today', 'tomorrow'];
      let data = {} as PatientResultsForAllPatients['globalDays'];

      scenarios.forEach(scenario => {
        const patientDays = getPatient(scenario);
        const caregiverDays = getCaregiver(scenario);

        data[scenario] = {
          patient: patientDays,
          caregiver: caregiverDays,
          total: patientDays + caregiverDays,
        };
      });

      data.delta = data.tomorrow.total - data.today.total;

      return data;
    };

  return {
    detailHours: {
      ...scenariosResults,
      delta: {
        hoursPercent: decimalToPercentage(hoursRatio),
      },
    },
    globalCost: getGlobalCostResults(),
    globalDays: getGlobalDaysResults(),
  };
};
