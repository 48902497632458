import React, {useCallback, useState} from 'react';
import {NumberInput, NumberInputField} from '@chakra-ui/react';

import {CustomNumberInputProps} from '@atoms/inputNumber/definitions';
import {clampMax, clampMin} from '@utils/math';

const isValidCharacter = (character: string) => {
  return /^[0-9]$/.test(character);
};

const CustomNumberInput = React.forwardRef<
  HTMLInputElement,
  CustomNumberInputProps
>((props, ref) => {
  const {
    clampOnChange = true,
    isInvalid,
    onChange: propOnChange,
    value: propValue,
    defaultValue = '',
    min = 0,
    max = undefined,
    ...otherProps
  } = props;

  const isControlled = typeof propValue !== 'undefined';

  const [internalValue, setInternalValue] = useState(defaultValue);

  const handleOnChange = useCallback(
    (valueAsString: string) => {
      let stringValue = valueAsString;
      let numberValue = parseFloat(stringValue);

      if (clampOnChange && valueAsString !== '') {
        if (!isNaN(numberValue)) {
          if (min !== undefined) {
            numberValue = clampMin(numberValue, min);
          }
          if (max !== undefined) {
            numberValue = clampMax(numberValue, max);
          }
          stringValue = numberValue.toString();
        }
      }

      if (!isControlled) {
        setInternalValue(stringValue);
      }

      propOnChange?.(stringValue);
    },
    [clampOnChange, isControlled, propOnChange, min, max],
  );

  return (
    <NumberInput
      width={63}
      onChange={handleOnChange}
      value={isControlled ? propValue : internalValue}
      defaultValue={defaultValue}
      isValidCharacter={isValidCharacter}
      errorBorderColor="secondary.main"
      focusBorderColor={isInvalid ? 'secondary.main' : 'neutral.100'}
      isInvalid={isInvalid}
      min={min}
      max={max}
      {...otherProps}>
      <NumberInputField
        ref={ref}
        bg="common.white"
        height="44px"
        textAlign="center"
        p={0}
        borderRadius={0}
        fontSize={24}
        color="primary.main"
        _disabled={{
          backgroundColor: 'neutral.100',
          color: 'neutral.200',
        }}
      />
    </NumberInput>
  );
});

export default CustomNumberInput;
