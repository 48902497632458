import {Box, Table, Tr, Th, Tbody, Td} from '@chakra-ui/react';

import {Delta} from '@atoms/Delta';
import {Text} from '@atoms/Text';

import {SinglePatient} from './interfaces';

const SinglePatientTable = ({
  totalHoursEv,
  totalHoursSc,
  socialCostEv,
  socialCostSc,
  lostDaysEv,
  lostDaysSc,
  costDelta,
  lostDaysDelta,
}: SinglePatient) => {
  return (
    <Box position="relative" w={440} h="fit-content">
      <Table variant="simple" w={320}>
        <Tbody>
          <Tr>
            <Th px={0} w="25%">
              <Text
                color="common.black"
                textTransform="capitalize"
                textAlign="right">
                Ore totali
              </Text>
            </Th>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {totalHoursEv}
              </Text>
            </Td>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {totalHoursSc}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Th px={0} w="25%">
              <Text
                color="common.black"
                textTransform="capitalize"
                textAlign="right">
                Costo sociale
              </Text>
            </Th>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {socialCostEv}
              </Text>
            </Td>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {socialCostSc}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Th px={0} w="25%">
              <Text
                color="common.black"
                textTransform="capitalize"
                textAlign="right">
                Giornate lavorative perse
              </Text>
            </Th>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {lostDaysEv}
              </Text>
            </Td>
            <Td>
              <Text variant="bodyMedium" color="neutral.200">
                {lostDaysSc}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {costDelta !== '' && <Delta delta={costDelta} top="30%" />}
      {lostDaysDelta !== '' && (
        <Delta delta={lostDaysDelta} bottom="5%" alignItems="end" />
      )}
    </Box>
  );
};

export default SinglePatientTable;
