import {StylesObject} from '@definitions';
import {theme} from '@theme/theme';

export const styles: StylesObject = {
  container: {
    backgroundColor: theme.colors.neutral[100],
    width: '100%',
    height: '67.5px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
};
