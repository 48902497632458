import {Box} from '@chakra-ui/react';

import {IconSvg} from '@atoms/IconSvg';
import {PopUp} from '@atoms/PopUp';
import {Text} from '@atoms/Text';

const InfusionPopUp = () => {
  const InfusionText = (
    <Box w={260} textAlign="left">
      <Text mb="20px">
        Il{' '}
        <Text variant="bodySmallBold" as="span">
          tempo di infusione e osservazione
        </Text>{' '}
        del personale ospedaliero si riferisce al tempo effettivo dedicato dalla
        figura selezionata all’assistenza del paziente durante la
        somministrazione per via endovenosa.
      </Text>
      <Text>
        Ad esempio: se un paziente sta in poltrona per la somministrazione EV 90
        min e l’infermiere lo assiste solo per 20 min, il tempo da inserire è 20
        min.
      </Text>
    </Box>
  );

  return (
    <PopUp
      trigger={<IconSvg icon="info" />}
      message={InfusionText}
      placement="top"
    />
  );
};

export default InfusionPopUp;
